import React, { useEffect, useState } from 'react'
import { Link, useLocation, useParams } from 'react-router-dom'
import { orderList, tempSingleOrder } from '../../Data/localData';
import { Col, Container, Row } from 'react-bootstrap';
import { FaArrowLeftLong } from "react-icons/fa6";
import { RiDeleteBin6Line } from "react-icons/ri";
import { IoDuplicate } from "react-icons/io5";
import { MdEdit } from "react-icons/md";
import Dropdown from 'react-bootstrap/Dropdown';
import { LuDot } from "react-icons/lu";
import { MdOutlineDone } from "react-icons/md";
import { GoDotFill } from "react-icons/go";
import { TiArrowSortedDown } from "react-icons/ti";
import { TiArrowSortedUp } from "react-icons/ti";
import { BsDownload } from "react-icons/bs";
import { frontEnd_API, header, SERVER_URL, siteConfig } from '../../Config/Config';
import axios from 'axios';
import { usePDF } from 'react-to-pdf';
import ProductPreviewImage from './Products/ProductPreviewImageComponent';

function SellerSingleOrderPage() {
    const params = useParams();
    const location = useLocation();
    const [orderData, setOrderData] = useState();
    const [details, setDetails] = useState(false);
    const moment = require('moment');
    const { toPDF, targetRef } = usePDF({ filename: 'order.pdf' });

    const getSingleOrder = async () => {
        try {
            const { data } = await axios.get(`${frontEnd_API.orders}/${params.orderId}`, header)
            console.log("data::", data);
            setOrderData(data?.data)
            if (data?.status === 400) {
                window.location = '/orders'
            }
        }
        catch (e) {
            console.log("e::", e);
        }
    }

    useEffect(() => {
        getSingleOrder();
    }, [])

    const statusBar = [
        {
            label: 'created',
            value: 1,
            date: 'Jul 10 6:10 PM'
        },
        {
            label: 'On hold',
            value: 1,
            date: 'Jul 11 4:12 PM'
        },
        {
            label: 'Shipped',
            value: 0,
        },
        {
            label: 'Delivered',
            value: 0,
        }
    ];

    document.title = `#${orderData?.orderId} | ${siteConfig?.name}`;

    return (
        <div className='py-2 custom-single-order-container'>
            <Container className='custom-single-order py-4'>
                <div className='d-grid'>
                    <div className='d-flex'>
                        <Link to={'/orders'} className='custom-single-order-back-link'><FaArrowLeftLong />Back</Link>
                    </div>
                    <div className='d-grid gap-2 gap-lg-0 d-lg-flex justify-content-sm-between align-items-center pt-3'>
                        <div className='d-grid'>
                            <span className='h3 fw-semibold m-0 fs-sm-20 text-capitalize'>{orderData?.orderType} Order #{orderData?.orderId}</span>
                            <span className='p-color fs-14 fs-sm-12'>Created {moment(orderData?.orderDate).format('MMM D, YYYY h:mm A')}</span>
                        </div>
                        <div className='d-none d-sm-flex gap-2 custom-order-header-btn justify-content-sm-end'>
                            {/* <Link className='flex-between-align gap-2 fs-14'><RiDeleteBin6Line />Cancel order</Link> */}
                            {/* <Link className='flex-between-align gap-2 fs-14'><IoDuplicate />Duplicate</Link> */}
                            <Link className='flex-between-align gap-2 fs-14' onClick={() => toPDF()}><BsDownload />Download Invoice</Link>
                        </div>
                        <div className='d-grid d-sm-none w-100'>
                            <Dropdown className='w-100'>
                                <Dropdown.Toggle variant="light" id="dropdown-basic" className='w-100' style={{ backgroundColor: "white" }}>
                                    More
                                </Dropdown.Toggle>

                                <Dropdown.Menu className='w-100'>
                                    <Dropdown.Item className='d-flex align-items-center gap-2'><RiDeleteBin6Line />Cancel order</Dropdown.Item>
                                    <Dropdown.Item className='d-flex align-items-center gap-2'><IoDuplicate />Duplicate</Dropdown.Item>
                                    <Dropdown.Item className='d-flex align-items-center gap-2'><MdEdit />Edit order</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                    </div>
                </div>
                <Row className='pt-3' ref={targetRef}>
                    <Col className='col-12 col-md-8'>
                        <div className='d-grid seller-box p-3 p-lg-4 gap-3'>

                            {/* Product Title */}
                            {
                                (orderData?.orderChildData?.length > 0) &&
                                orderData?.orderChildData.map((item, index) => {
                                    const colorCode = item?.childOrderVariantData?.filter(e => ['color', 'colors', 'colour', 'colours']?.includes(e?.parentName))?.map(e => e?.variantCode)
                                    return (
                                        <div key={index} className='d-flex gap-2'>
                                            <div className='d-grid w-100 align-content-start gap-1 gap-sm-2'>
                                                <b className='fs-20 fs-lg-18 fs-sm-16 text-capitalize d-none d-sm-block'>{item?.productName}</b>
                                                <b className='fs-20 fs-lg-18 fs-sm-16 text-capitalize d-block d-sm-none'>{item?.productName.substring(0, 12) + '...'}</b>
                                                <div className='d-flex align-items-center gap-1 fs-14 fs-sm-12'>
                                                    <span className='text-capitalize'>Price : ₹{parseInt(item?.productMrp)}</span>
                                                    <span className='text-capitalize pe-2'>Qty : {parseInt(item?.productQuantity)}</span>
                                                    {/* <LuDot />
                                                    <span className='text-capitalize'>{parseInt(item?.productQuantity)}</span>
                                                    <LuDot />
                                                    <span className='text-capitalize'>{orderData?.productSize}</span> */}
                                                </div>
                                            </div>
                                            <div className='d-grid custom-order-product-img border-card'>
                                                {/* <img src={(item?.productThumbnail) ? SERVER_URL + item?.productThumbnail : require('../../Assets/Images/Heading (1).png')} alt="" /> */}
                                                <ProductPreviewImage
                                                    colorCode={colorCode ?? '#ffffff'}
                                                    isButtonShow={false}
                                                    objectData={{
                                                        preview: item?.productThumbnail || null,
                                                    }} />
                                            </div>
                                        </div>
                                    )
                                })
                            }

                            {/* Order Status Bar */}
                            <div className='d-block d-sm-flex flex-between-align custom-order-status-bar mb-sm-3'>
                                {
                                    statusBar.map((item, index) => {
                                        let cnt = 0;
                                        statusBar.forEach((statusItem) => {
                                            if (statusItem.value === 1) {
                                                cnt++;
                                            }
                                        });
                                        return (
                                            <div key={index}
                                                className={`w-100 d-flex align-items-center custom-order-status-bar-div gap-2 fw-semibold fs-14 
                                                    ${item.value === 1 && 'custom-bg-info text-light'}
                                                    ${index === (cnt - 1) && 'last-child-style'}`}
                                            >
                                                {(item.value === 1)
                                                    ? < MdOutlineDone />
                                                    : <GoDotFill />
                                                }
                                                <span className='text-capitalize'>{item.label}</span>
                                                <section className='fw-meduim fs-12 fs-sm-10'>{item.date}</section>
                                            </div>
                                        )
                                    })
                                }
                            </div>

                            {/* Price List */}
                            <div className='d-grid pt-2 pt-sm-4 gap-1 gap-sm-2'>
                                <div className='custom-price-list-table'>
                                    <div>
                                        <table>
                                            <thead>
                                                <tr>
                                                    <th className='fs-14 text-center'>Product name</th>
                                                    <th className='fs-14 text-center'>Qty</th>
                                                    <th className='fs-14 text-center'>Price</th>
                                                    <th className='fs-14 text-center'>Total</th>
                                                    <th className='fs-14 text-center'>Shipping</th>
                                                    <th className='fs-14 text-center'>Discount</th>
                                                    <th className='fs-14 text-center'>Sub Total</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    (orderData?.orderChildData?.length > 0) &&
                                                    orderData?.orderChildData?.map((item, index) => {
                                                        return (
                                                            <tr className='fs-14'>
                                                                <td className='ps-2'>{item?.productName.substring(0, 12) + '...'}</td>
                                                                <td className='text-center'>{parseInt(item?.productQuantity)}</td>
                                                                <td className='text-center'>{parseInt(item?.productPrice)}</td>
                                                                <td className='text-center'>{parseInt(item?.productSubTotal)}</td>
                                                                <td className='text-center'>{((parseInt(item?.productShippingCharge) !== 0) ? '-' : '') + parseInt(item?.productShippingCharge)}</td>
                                                                <td className='text-center'>{((parseInt(item?.productDiscount) !== 0) ? '-' : '') + parseInt(item?.productDiscount)}</td>
                                                                <td className='text-center fw-semibold'>₹{parseInt(item?.productSubTotal) - (parseInt(item?.productShippingCharge)) - parseInt(item?.productDiscount)}</td>
                                                            </tr>
                                                        )
                                                    })
                                                }
                                            </tbody>
                                            <tfoot>
                                                <tr>
                                                    <th className='fs-16 text-center' colSpan={6}>Grand Total</th>
                                                    <th className='fs-16 text-center'>₹{parseInt(orderData?.orderNetAmount)}</th>
                                                </tr>
                                            </tfoot>
                                        </table>
                                    </div>
                                </div>

                                <div className='d-flex justify-content-end pt-3'>
                                    <Link className='seller-box link-to-normal semi-border-rad py-2 px-3 text-capitalize fw-semibold' onClick={() => setDetails(!details)}>
                                        <span className='pe-1'>Details</span>
                                        {
                                            (!details)
                                                ? <TiArrowSortedDown className='fs-18' />
                                                : <TiArrowSortedUp className='fs-18' />
                                        }
                                    </Link>
                                </div>
                            </div>

                            {/* More Details */}
                            {
                                (details) &&
                                <div className='timeline'>
                                    {
                                        (orderData?.orderTrackingData?.length > 0) &&
                                        orderData?.orderTrackingData.map((item, index) => {
                                            return (
                                                <div key={index} className={`timeline-item ${((orderData?.orderTrackingData?.length - 1) === index) ? 'lastItem' : ''}`}>
                                                    <div
                                                        className={`timeline-icon circle-one mt-1 ms-1`}
                                                        style={{
                                                            backgroundColor: (index === 0) ? 'darkblue' : 'grey',
                                                            outlineColor: (index === 0) ? 'darkblue' : 'grey',
                                                        }}
                                                    ></div>
                                                    <div className='timeline-content'>
                                                        <span className='timestamp'>{moment(item?.updatedAt).format('MMM D, YYYY h:mm A')}</span>
                                                        <div>
                                                            <b className='text-capitalize'>{item?.trackingStatus}</b>
                                                            <span className='description'>{item?.trackingRemark}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            }
                        </div>
                    </Col>
                    <Col className='col-12 col-md-4 gap-2 pt-4 pt-md-0'>
                        <div className='d-grid seller-box align-content-start' style={{ overflow: 'hidden' }}>
                            <div className='flex-between-align p-3 border-bottom'>
                                <h5 className='fw-semibold m-0 fs-lg-16'>Customer</h5>
                                {/* <Link className='fs-18 fs-lg-14 link-to-normal flex-between-align gap-2'><MdEdit />Edit</Link> */}
                            </div>
                            <div className='d-grid p-3 border-bottom'>
                                <b className='fw-semibold fs-lg-16 fs-18 pb-1'>Personal details</b>
                                <span className='fs-lg-12 fs-14 d-flex gap-1 fw-semibold'>
                                    <i className="bi bi-person-circle"></i>
                                    {orderData?.orderBillingName}
                                </span>
                                <span className='fs-lg-12 fs-14 d-flex gap-1'>
                                    <i className="bi bi-envelope"></i>
                                    {orderData?.orderBillingEmail}
                                </span>
                                <span className='fs-lg-12 fs-14 d-flex gap-1'>
                                    <i className="bi bi-telephone-fill"></i>
                                    {orderData?.orderBillingMobile}
                                </span>
                            </div>
                            <div className='d-grid p-3'>
                                <b className='fw-semibold pb-1 fs-lg-16 fs-18'>Shipping address</b>
                                <p className='m-0 fs-lg-12 fs-14 p-color d-flex flex-wrap text-capitalize'>
                                    {orderData?.orderBillingAddressLine1}, {orderData?.orderBillingAddressLine2}, {orderData?.orderBillingArea}, {orderData?.orderBillingCity}, {orderData?.orderBillingState}, {orderData?.orderBillingCountry} - {orderData?.orderBillingPincode}
                                </p>
                            </div>
                        </div>
                        <div className='mt-4 d-grid seller-box align-content-start' style={{ overflow: 'hidden' }}>
                            <div className='flex-between-align p-3 border-bottom'>
                                <h5 className='fw-semibold m-0 fs-lg-16'>Billing</h5>
                                {/* <Link className='fs-16 fs-lg-14 fw-semibold link-to-normal seller-box flex-between-align  px-lg-3 py-lg-1 px-md-2 px-3 py-1 gap-2'><BsDownload />Download</Link> */}
                            </div>
                            <div className='flex-between-align p-3 border-bottom'>
                                <span className='fs-lg-14'>Total Products value</span>
                                <b className='fs-lg-14 text-success'>₹{orderData?.orderSubTotalAmount}</b>
                            </div>
                            <div className='d-grid gap-1 p-3 border-bottom'>
                                <div className='flex-between-align'>
                                    <span className='fs-lg-14'>Total Shipping cost</span>
                                    <b className='fs-lg-14 text-danger'>- ₹{orderData?.orderShippingChargeAmount}</b>
                                </div>
                            </div>
                            <div className='flex-between-align p-3'>
                                <b className='fs-lg-14'>Total costs</b>
                                <b className='color-info fs-lg-16 fs-18'>₹{orderData?.orderNetAmount}</b>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default SellerSingleOrderPage