import React, { useState, useRef, useEffect } from 'react';
import { Link, NavLink, useLocation, useNavigate } from 'react-router-dom';
import { BsFillLightningChargeFill } from "react-icons/bs";
import { FaShop } from 'react-icons/fa6';
import { BsArrowsExpand, BsArrowsCollapse } from "react-icons/bs";
import { IoIosArrowForward } from "react-icons/io";
import { sellerSideBarData } from '../../Data/localData';
import Accordion from 'react-bootstrap/Accordion';
import axios from 'axios';
import { frontEnd_API } from '../../Config/Config';
import { removeLoggedUser } from '../../Store/Slices/UserSlice';
import { useDispatch, useSelector } from 'react-redux';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import Redirection from '../../Components/Redirection/Redirection';
import { removeStore } from '../../Store/Slices/StoreSlice';

function SellerSideBar({ onStateChange }) {
  const [toggleBar, setToggleBar] = useState(false);
  const [storeMenu, setStoreMenu] = useState(true);
  const [customMenu, setCustomMenu] = useState(true);
  const [headerMenu, setHeaderMenu] = useState(true);
  const [userMenu, setUserMenu] = useState(true);
  const location = useLocation();
  const storeRef = useRef(null);
  const customRef = useRef(null);
  const headerRef = useRef(null);
  const userRef = useRef(null);
  const [activeSubMenu, setActiveSubMenu] = useState(null);
  const user = useSelector((state) => state.user.value);
  const store = useSelector((state) => state.store.value);
  const dispatch = useDispatch();
  const navigate = useNavigate();


  useEffect(() => {
    onStateChange(toggleBar);
  }, [toggleBar, onStateChange])

  const customBothToggele = () => {
    setToggleBar(!toggleBar);
    setStoreMenu(true);
  }

  const handleClickOutside = (event) => {
    if (customRef.current && !customRef.current.contains(event.target)) {
      setCustomMenu(true);
    }
  };
  const handleHeaderClickOutside = (event) => {
    if (headerRef.current && !headerRef.current.contains(event.target)) {
      setHeaderMenu(true);
    }
  };
  const handleUserClickOutside = (event) => {
    if (userRef.current && !userRef.current.contains(event.target)) {
      setUserMenu(true);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);
  useEffect(() => {
    document.addEventListener('mousedown', handleHeaderClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleHeaderClickOutside);
    };
  }, []);
  useEffect(() => {
    document.addEventListener('mousedown', handleUserClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleUserClickOutside);
    };
  }, []);

  const removeUser = () => {
    const customHeader = {
      headers: {
        'token': `Bearer ${user?.token}`,
        'Content-Type': 'application/json'
      }
    }
    axios.get(frontEnd_API.signout, customHeader)
      .then((res) => {
        console.log('Res::', res);
        dispatch(removeLoggedUser());
        dispatch(removeStore());
        navigate('/');
        window.location.reload();
      })
      .catch((e) => {
        console.log(e);
      })
  }

  const forNavigate = () => {
    navigate('/create-store')
  }

  const [tag, setTag] = useState([]);

  useEffect(() => {
    const customHeader = {
      headers: {
        'token': `Bearer ${user?.token}`,
        'Content-Type': 'application/json'
      }
    }
    axios.get(frontEnd_API.getcategory, customHeader)
      .then((res) => {
        sellerSideBarData[1].data = res?.data?.data;
      })
      .catch((e) => {
        console.log("e::", e);
      })

    axios.get(frontEnd_API.gettag, customHeader)
      .then((res) => {
        setTag(res?.data?.data);
      })
      .catch((e) => {
        console.log("e::", e);
      })

  }, [user?.token])

  const handleSubMenuToggle = (index) => {
    setActiveSubMenu((prevIndex) => (prevIndex === index ? null : index));
  };

  const handleExtrenalLinks = (link) => {
    window.open(link, '_blank');
  }

  return (
    <div className='seller-panel-sidebar pt-lg-3' style={{ width: `${(toggleBar) ? '75px' : '100%'}` }}>

      {/* Display after large size */}
      <section className='d-none d-lg-grid gap-3 align-content-start'>
        {/* Logo Part */}
        <div className='d-grid gap-3 align-content-start px-2'>
          <Link to={'/dashboard'} className='sidebar-img-container' style={{ padding: `${(toggleBar) ? '0' : '0rem 18%'}`, borderRadius: `${(toggleBar) ? '50%' : '0'}`, border: `${(toggleBar) ? '1px solid lightgray' : '0'}` }}>
            {
              (!toggleBar)
                ? <img src={require('../../Assets/Images/oglogo.png')} alt="" />
                : <div className='custom-lightning'><BsFillLightningChargeFill /></div>
            }
          </Link>
          <div className='sidebar-toggle-btn' onClick={customBothToggele}>
            <i className={(!toggleBar) ? "bi bi-caret-left-fill" : "bi bi-caret-right-fill"}></i>
          </div>
        </div>
        {/* For scrollbar */}
        <div className='h-100 custom-scroll-bar d-grid gap-2 pb-0' style={{ borderTop: '1px solid black' }}>
          {/* Store Part */}
          <div className={(!toggleBar) ? 'sidebar-store-tab py-2 px-2 flex-between-align' : 'sidebar-store-tab py-2 px-2'}>
            <div
              className={(!toggleBar)
                ? 'sidebar-store-tab-in flex-between-align w-100 py-2 px-2 gap-3'
                : 'sidebar-store-tab-in d-flex align-items-center justify-content-center py-2'}
              style={{ backgroundColor: `${(!customMenu) ? 'rgb(0, 0, 48)' : ''}`, cursor: 'pointer', color: `${(!customMenu) ? 'white' : ''}` }}
              onClick={() => setCustomMenu(!customMenu)}
              ref={customRef}
            >
              <FaShop className='fs-20' />
              <span className={(!toggleBar) ? 'flex-between-align w-100' : 'd-none'}>
                <h5 className="m-0 fs-16 text-capitalize fw-semibold">{store?.name}</h5>
                <BsArrowsExpand className={(customMenu) ? 'd-block' : 'd-none'} />
                <BsArrowsCollapse className={(customMenu) ? 'd-none' : 'd-block'} />
              </span>

              <div className={`custom-store-menu ${(customMenu) ? 'd-none' : 'd-grid'}`} style={{ left: `${(!toggleBar) ? '13%' : '75px'}` }}>
                <Link className='d-flex gap-2 store-display-part'>
                  <FaShop className='fs-20 pt-1' />
                  <div className='d-grid align-content-start'>
                    <h5 className='m-0 text-capitalize'>{store?.name}</h5>
                    <span className='fs-12'>Connected</span>
                  </div>
                </Link>
                <hr className='my-2' />
                <Link className='text-decoration-none' to={'/my-store'}>Manage my store</Link>
                <div onClick={forNavigate}>
                  <Link className={'text-decoration-none flex-between-align'}>
                    <span>Add a new store</span>
                    <i className="bi bi-plus-lg"></i>
                  </Link>
                </div>
              </div>
            </div>
          </div>
          {/* List Of Sidebar Items */}
          <div className='sidebar-menu-list d-grid pb-2 px-2 gap-1'>
            {
              sellerSideBarData.map((item, index) => {
                return (
                  <OverlayTrigger
                    placement="right"
                    overlay={<Tooltip className={`text-capitalize ${(!toggleBar) && 'd-none'}`} id="tooltip-disabled">{item.label}</Tooltip>}
                    key={index}
                  >
                    <NavLink
                      to={(item.link) ? item.link : location.pathname}
                      target={`${(item.externalLink) ? '_blank' : '_self'}`}
                      className={({ isActive }) => `sidebar-menu-item ${(isActive && item.link) ? 'custom-active-item' : ''}`}
                      onClick={() => handleSubMenuToggle(index)}
                    >
                      <i className={`bi ${(item.icon)} fs-20 fw-semibold`}></i>
                      <span className={(!toggleBar) ? 'flex-between-align w-100' : 'd-none'}>
                        <h5 className='m-0 text-capitalize fs-16'>{item.label}</h5>
                        <IoIosArrowForward className={(item?.data) ? 'd-block fs-18' : 'd-none'} style={{ transform: `rotate(${(activeSubMenu === index) ? '180deg' : '0deg'})` }} />
                      </span>
                      {
                        (item?.data)
                        && <div
                          className={`sidebar-menu-sub-item gap-1 align-content-start ${activeSubMenu === index ? 'd-grid align-content-center' : 'd-none'} ${(item?.label === 'category') && 'd-flex gap-2 custom-menu-peta-item'}`}
                          style={{ left: `${(!toggleBar) ? '13%' : '75px'}` }}
                        >
                          {
                            (item.data.length)
                              ? <>
                                <div className='custom-close'><i className='bi bi-x-lg'></i></div>
                                <div className='d-grid align-content-start gap-2'>
                                  {
                                    (item.label === 'category') &&
                                    tag?.map((tagItem, tagIndex) => {
                                      return (
                                        <Redirection
                                          key={tagIndex}
                                          redirectValue={{
                                            url: (tagItem.label !== 'Catelog Home') ? `/category/${tagItem.label}` : '/category',
                                            data: tagItem
                                          }}
                                        >
                                          <Link className='h6 mb-3 text-capitalize fw-semibold'>{tagItem.label}</Link>
                                        </Redirection>
                                      )
                                    })
                                  }
                                </div>
                                {
                                  item?.data.map((subItem, subIndex) => {
                                    return (
                                      <div key={subIndex} className='d-grid align-content-start'>
                                        {
                                          (!subItem.link) ?
                                            <Redirection
                                              key={subIndex}
                                              redirectValue={{
                                                url: `${item.label}/${subItem.label}`,
                                                data: subItem
                                              }}
                                            >
                                              <Link className='h6 mb-3 text-capitalize fw-semibold'>{subItem.label}</Link>
                                            </Redirection>
                                            :
                                            <Link onClick={() => handleExtrenalLinks(subItem?.link)} className='h6 mb-3 text-capitalize fw-semibold'>{subItem.label}</Link>
                                        }
                                        {
                                          (subItem?.children) &&
                                          subItem?.children.map((petaItem, petaIndex) => {
                                            return (
                                              <Redirection
                                                key={petaIndex}
                                                redirectValue={{
                                                  url: `/${item.label}/${subItem.label}/${petaItem.label}`,
                                                  data: { 'category': subItem, 'subCategory': petaItem }
                                                }}
                                              >
                                                <Link className='fs-14 text-capitalize'>{petaItem.label}</Link>
                                              </Redirection>
                                            )
                                          })
                                        }
                                      </div>
                                    )
                                  })
                                }
                              </>
                              : <div className='d-grid align-content-center'>
                                <div className='custom-close'><i className='bi bi-x-lg'></i></div>
                                <h4 className='fw-semibold'>No data found</h4>
                              </div>

                          }
                        </div>
                      }
                    </NavLink>
                  </OverlayTrigger>
                )
              })
            }
          </div>
          {/* Account Center */}
          <div ref={userRef} className={(!toggleBar) ? 'sidebar-user-tab py-2 px-2 flex-between-align' : 'sidebar-user-tab py-2 px-2'}>
            <Link
              onClick={() => setUserMenu(!userMenu)}
              className={(!toggleBar)
                ? 'sidebar-user-tab-in flex-between-align w-100 py-2 px-2 gap-3'
                : 'sidebar-user-tab-in d-flex align-items-center justify-content-center py-2'}
              style={{ backgroundColor: `${(!userMenu) ? 'rgb(0, 0, 48)' : 'transparent'}`, color: `${(!userMenu) ? 'white' : ''}` }}
            >
              <i className="bi bi-person-circle fs-20"></i>
              <span className={(!toggleBar) ? 'flex-between-align w-100' : 'd-none'}>
                <h5 className="m-0 fs-16">Account</h5>
                <IoIosArrowForward />
              </span>
              {/* Store Menu Design */}
              <div className={`custom-user-menu ${(userMenu) ? 'd-none' : 'd-grid'}`} style={{ left: `${(!toggleBar) ? '13%' : '75px'} ` }}>
                <Link className='text-decoration-none' to={'/account/details'}>My account</Link>
                <Link className='text-decoration-none' to={'/account/uploaded-files'}>My files</Link>
                <Link className='text-decoration-none' to={'/account/referral-program'}>Referral programs</Link>
                <Link className='text-decoration-none' to={'/account/api'}>Connections</Link>
                <Link className='text-decoration-none' to={'/account/settings'}>Setings</Link>
                <hr className='my-2' />
                <Link className='text-decoration-none flex-between-align' onClick={removeUser}>Sign Out</Link>
              </div>
            </Link>
          </div>
        </div>
      </section >

      {/* Display before large size */}
      < section className='custom-top-bar d-flex d-lg-none' >
        <Link to={'/dashboard'} className='custom-top-bar-img-container'>
          <img src={require('../../Assets/Images/oglogo.png')} alt="" />
        </Link>
        <div className='d-flex align-items-center top-bar-end-part gap-2 gap-sm-3'>
          <div className='custom-top-bar-lightning' ref={storeRef} onClick={() => setStoreMenu(!storeMenu)}>
            <BsFillLightningChargeFill className='cutom-thunder' />
            <div className={`custom-store-menu ${(storeMenu) ? 'd-none' : 'd-grid'} `}>
              <Link className='d-flex gap-2 store-display-part' onClick={() => setStoreMenu(true)}>
                <FaShop className='fs-20 pt-1' />
                <div className='d-grid align-content-start'>
                  <h5 className='m-0 text-capitalize'>{store?.name}</h5>
                  <span className='fs-12'>Connected</span>
                </div>
              </Link>
              <hr className='my-2' />
              <Link to={'/my-store'} className='text-decoration-none' onClick={() => setStoreMenu(true)}>Manage my store</Link>
              <Link to={'/create-store'} onClick={() => setStoreMenu(true)} className='text-decoration-none flex-between-align'><span>Add a new store</span> <i className="bi bi-plus-lg"></i></Link>
            </div>
          </div>

          <div className="custom-top-bar-list-container" ref={headerRef}>
            <span className='bi bi-list fs-25' onClick={() => setHeaderMenu(!headerMenu)}></span>
            <div className={`custom-top-bar-list ${(!headerMenu) ? 'd-grid' : 'd-none'} `}>
              <Accordion>
                {
                  sellerSideBarData.map((item, index) => {
                    return (
                      <Accordion.Item eventKey={index} key={index} className={(!item?.data) && 'custom-remove-icon'}>
                        <Accordion.Header as={Link} to={(item.link) ? item.link : location.pathname} onClick={() => setHeaderMenu((!item?.data) && true)}>
                          <Link className='d-flex gap-2 text-capitalize' to={(item.link) ? item.link : location.pathname}>
                            <i className={`bi ${item.icon}`}></i>
                            <span>{item.label}</span>
                          </Link>
                        </Accordion.Header>
                        {
                          (item?.data) &&
                          <Accordion.Body className='p-2 d-grid gap-1'>
                            <Accordion className='custom-header-accordian-height' style={{ height: 'auto' }}>
                              {
                                item?.data.map((subItem, subIndex) => {
                                  return (
                                    <Accordion.Item eventKey={subIndex} key={subIndex} className={`py - 0 ${!subItem?.children && 'custom-remove-icon'} `}>
                                      <Redirection
                                        key={subIndex}
                                        redirectValue={{
                                          url: `${item.link}/${subItem.label}`,
                                          data: subItem
                                        }}
                                      >
                                        <Accordion.Header className='custom-header-for-padding'>
                                          <div className='link-to-normal py-0 text-capitalize'>
                                            {subItem.label}
                                          </div>
                                        </Accordion.Header>
                                      </Redirection>
                                      <Accordion.Body className='p-2 px-4 d-grid gap-1'>
                                        {
                                          subItem?.children && subItem.children.map((petaItem, petaIndex) => (
                                            <Redirection
                                              key={petaIndex}
                                              redirectValue={{
                                                url: `${item.link}/${subItem.label}/${petaItem.label}`,
                                                data: { 'category': subItem, 'subCategory': petaItem }
                                              }}
                                            >
                                              <Link className='link-to-normal text-capitalize' onClick={() => setHeaderMenu()}>
                                                {petaItem.label}
                                              </Link>
                                            </Redirection>
                                          ))
                                        }
                                      </Accordion.Body>
                                    </Accordion.Item>
                                  )
                                })
                              }
                            </Accordion>
                          </Accordion.Body>
                        }
                      </Accordion.Item>
                    )
                  })
                }
                <Accordion.Item eventKey={99}>
                  <Accordion.Header onClick={() => setHeaderMenu(false)}>
                    <Link className='d-flex gap-2'>
                      <i className="bi bi-person-circle fs-20"></i>
                      <span>Account</span>
                    </Link>
                  </Accordion.Header>
                  <Accordion.Body className='p-2 px-4 d-grid gap-1'>
                    <Link className='link-to-normal' to={'/account/details'} onClick={() => setHeaderMenu(true)}>My Account</Link>
                    <Link className='link-to-normal' to={'/account/uploaded-files'} onClick={() => setHeaderMenu(true)}>My Files</Link>
                    <Link className='link-to-normal' to={'/account/referral-program'} onClick={() => setHeaderMenu(true)}>Referral Programs</Link>
                    <Link className='link-to-normal' to={'/account/api'} onClick={() => setHeaderMenu(true)}>Connections</Link>
                    <Link className='link-to-normal' to={'/account/settings'} onClick={() => setHeaderMenu(true)}>Settings</Link>
                    <hr className='my-1' />
                    <Link className='link-to-normal' onClick={removeUser}>Sign Out</Link>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </div>
          </div >
        </div >
      </section >
    </div >
  );
}

export default SellerSideBar;