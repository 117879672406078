import { configureStore } from '@reduxjs/toolkit';
import userReducer from './Slices/UserSlice';
import storeReducer from './Slices/StoreSlice';
import canvasSlice from './Slices/canvasSlice';
import DomainSlice from './Slices/DomainSlice';

export const store = configureStore({
  reducer: {
    user: userReducer,
    store: storeReducer,
    canvas: canvasSlice,
    domain: DomainSlice,
  },
});
