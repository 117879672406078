import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import './Assets/sass/style.scss';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import routes from './Routes/routes';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import 'react-toastify/dist/ReactToastify.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "@fontsource/poppins";
import "@fontsource/poppins/400.css";
import "@fontsource/poppins/600.css";
import "@fontsource/poppins/700.css";
import "@fontsource/poppins/900.css";
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import 'react-tooltip/dist/react-tooltip.css'
import SellerSideBar from './View/SellerDashboard/SellerSideBar';

function App() {
  const user = useSelector((state) => state.user.value);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (user) {
      if (location.pathname.endsWith('/signup') || location.pathname.endsWith('/signin')) {
        navigate('/');
      }
    }

    if (!user) {
      const { pathname } = location;

      const isRestrictedPath =
        pathname.startsWith('/') ||
        pathname === '/create-store';

      const isSignUpOrSignInPage =
        pathname.endsWith('/signup') ||
        pathname.endsWith('/signin');

      if (isRestrictedPath && !isSignUpOrSignInPage) {
        navigate('/signin');
      }
    }
  }, [user, location, navigate]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  const showHeaderFooter = (!location.pathname.startsWith('/signin') && !location.pathname.startsWith('/signup') && user?.storeId !== 0 && !location.pathname.endsWith('/canvas') && !location.pathname.endsWith('/app/create-store'));
  const [childStateValue, setChildStateValue] = useState();

  const handleChildStateChange = (value) => {
    setChildStateValue(value);
  };

  useEffect(() => {
    if (user) {
      if (user?.storeId === 0) {
        navigate('/app/create-store');
      }
    }
  }, [navigate, user]);

  return (
    <>
      <div className={(showHeaderFooter) && `d-lg-flex custom-dashboard-panel ${childStateValue ? 'is-collapsed' : ''}`}>
        {
          (showHeaderFooter) &&
          <div className='dashboard-panel-sidebar'>
            <SellerSideBar onStateChange={handleChildStateChange} />
          </div>}
        <div className={(showHeaderFooter) && 'dashboard-panel-content'}>
          <Routes>
            {
              routes.map((item, index) => {
                return (
                  <Route key={index} path={item.path} element={item.element} />
                )
              })
            }
          </Routes>
        </div>
      </div>

    </>
  );
}

export default App;