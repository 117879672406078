import React, { useEffect, useState } from 'react'
import { Alert, Button, Col, Container, Dropdown, Row, Table } from 'react-bootstrap';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { Link } from 'react-router-dom';
import { SiRazorpay } from "react-icons/si";
import { pattern } from '../../Config/Constant';
import FormComponent from '../../Components/FormComponent';
import { frontEnd_API, header, siteConfig } from '../../Config/Config';
import Modal from 'react-bootstrap/Modal';
import { GiCactusPot } from "react-icons/gi";
import { TbTransactionRupee } from "react-icons/tb";
import { openRazorpay } from '../../Components/InitializeRazorPay';
import { ToastContainer } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import moment from 'moment';
import { updateUser } from '../../Store/Slices/UserSlice';
import CommonPagination from '../../Components/CommonPagination';
import Swal from 'sweetalert2'

function SellerWallet() {
    const [show, setShow] = useState(false);
    const [showFilter, setShowFilter] = useState(false);
    const [calendar, setCalendar] = useState(false);
    const [showPrice, setShowPrice] = useState(false);
    const [filters, setFilters] = useState([]);
    const [amount, setAmount] = useState(0);
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const store = useSelector((state) => state.store.value);
    const user = useSelector((state) => state.user.value);
    const [filterList, setFilterList] = useState([]);
    const [multiSelect, setMultiSelect] = useState([]);
    const dispatch = useDispatch();
    const commaNumber = require('comma-number')

    const [state, setState] = useState([
        {
            startDate: new Date(),
            endDate: new Date(),
            key: 'selection'
        }
    ]);

    const handleChange = (event) => {
        const value = event.target.value;
        setFilters((prev) =>
            prev.includes(value)
                ? prev.filter((item) => item !== value)
                : [...prev, value]
        );
    }

    const handleDateChange = (e) => {
        console.log(e);
        setState([e]);
    }

    // Razorpay Integrate Code

    const handlePrice = (amt) => {
        setAmount(amt);
        console.log(amt);
    }

    // function for send wallet request ------------
    const submitRecharge = async (transactionNumber = '') => {
        if (transactionNumber) {
            try {
                const body = {
                    relatedId: store?.id,
                    relatedName: 'store',
                    amount,
                    transactionNumber,
                    particular: `Wallet Recharge with amount of ${amount} and generated transaction number ${transactionNumber}`,
                    type: 'credit',
                    walletType: "RECHARGE",
                    date: moment()?.format('YYYY-MM-DD')
                }
                const response = await axios.post(frontEnd_API?.sellerWallet, body, header);
                console.log('response from wallet:: ', response)
                dispatch(updateUser({
                    walletBalance: response?.data?.data?.walletBalance
                }))
                setAmount(0)
            } catch (error) {
                console.log('console.log :: error :: ', error)
            }
        }
    }
    // ------------

    const handlePayment = () => {
        const options = {
            key: "rzp_test_pPhRuH9aNA8DOL",
            amount: (amount * 100),
            currency: "INR",
            name: store?.name,
            description: "Test Transaction",
            image: store?.logo ?? "https://example.com/your_logo",
            handler: function (response) {
                submitRecharge(response.razorpay_payment_id)
            },
            prefill: {
                name: store?.name,
                email: store?.emailAddress,
                contact: store?.mobileNumber,
            },
            notes: {
                address: store?.billingAddressFirstLine + ", " + store?.billingAddressSecondLine,
            },
            theme: {
                color: "#3399cc",
            },
        };

        openRazorpay(options);
    };

    const customToggle = () => {
        handlePayment();
        setShowPrice(!showPrice);
        setAmount(0);
    }

    useEffect(() => {
        setStartDate((state[0].startDate) && `${state[0]?.startDate.getDate()}/${state[0]?.startDate.getMonth() + 1}/${state[0]?.startDate.getFullYear()}`)

        setEndDate((state[0].endDate) && `${state[0]?.endDate.getDate()}/${state[0]?.endDate.getMonth() + 1}/${state[0]?.endDate.getFullYear()}`)
    }, [state, startDate, endDate])

    const handleClick = (val) => {
        setFilters((prevData) => prevData.filter(value => value !== val))
        setState([
            {
                startDate: '',
                endDate: '',
                key: 'selection'
            }
        ]);
    }

    // function for get transaction/wallet data ------------

    const [transactionData, setTransactionData] = useState()
    const [totalCount, setTotalCount] = useState()

    const getTransactionData = async () => {
        console.log("{...filters}::", { ...filters });
        try {
            const { data } = await axios?.put(frontEnd_API?.sellerWallet, { ...filters }, header)
            setTransactionData([...data?.data])
            setTotalCount(data?.totalCount);
        } catch (error) {
            console.log("error::", error);
            setTransactionData([])
        }
    }

    useEffect(() => {
        getTransactionData()
    }, [filters])

    const getFilterData = async () => {
        try {
            const response = await axios?.get(`${frontEnd_API?.sellerWallet}/filter`, header);
            if (response?.status === 200) {
                setFilterList((prevData) => ({
                    ...prevData,
                    parentData: [
                        {
                            title: 'providerId',
                            data: response?.data?.data?.providerData
                        },
                        {
                            title: 'walletType',
                            data: response?.data?.data?.walletTypeData
                        }
                    ]
                }));
                console.log("getProviderData::", response?.data?.data);
            }
        } catch (error) {
            console.log("error::", error);
        }
    }

    useEffect(() => {
        getFilterData();
    }, [])

    const handleCheckTransaction = (item) => {
        const tempData = filters[item?.title] ?? [];

        const newData = (tempData?.length > 0) ? tempData.find((e) => e == item?.value) : null;
        if (newData) {
            const check = filters[item?.title].filter((e) => e != item?.value);
            setFilters((prevData) => ({
                ...prevData,
                [item?.title]: check
            }))
        }
        else {
            if (item?.value == '') {
                const data = filterList?.parentData?.find((e) => e?.title == item?.title);
                if (data?.data?.length == filters[item?.title]?.length) {
                    setFilters((prevData) => ({
                        ...prevData,
                        [item?.title]: []
                    }));
                }
                else {
                    const check = data?.data.map((prevData) => (
                        prevData?.value
                    ));
                    setFilters((prevData) => ({
                        ...prevData,
                        [item?.title]: check
                    }));
                }
            }
            else {
                setFilters((prevData) => ({
                    ...prevData,
                    [item?.title]: [...tempData, item?.value]
                }))
            }
        }

    }

    // 
    // Withdwarl code 
    // 

    const [withdrawList, setWithdrawList] = useState();
    const [withdrawTotalList, setWithdrawTotalList] = useState();
    const [withdrawFilters, setWithdrawFilters] = useState();
    const [withdrawPrice, setWithdrawPrice] = useState();

    const getWithdrawList = async () => {
        try {
            const response = await axios.put(frontEnd_API?.sellerwithdraw, { ...withdrawFilters }, header);
            if (response?.status == 200) {
                setWithdrawList(response?.data?.data);
                setWithdrawTotalList(response?.data?.totalCount);
                console.log("response?.data?.data::", response?.data);
            }
        }
        catch (e) {
            console.log("Err::", e);
        }
    }

    useEffect(() => {
        getWithdrawList();
    }, [withdrawFilters])

    const handleWithdraw = async () => {
        if (withdrawPrice > 0 && withdrawPrice <= user?.walletBalance) {
            try {
                Swal.fire({
                    title: `Request for ₹${withdrawPrice}!`,
                    text: `Are you sure to send request for ₹${withdrawPrice} ?`,
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#12715B',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Confirm',
                    cancelButtonText: 'No',
                }).then(async (result) => {
                    if (result.isConfirmed) {
                        const response = await axios.post(frontEnd_API?.sellerwithdraw, {
                            amount: withdrawPrice,
                            walletType: "WITHDRAW_REQUEST",
                            date: moment().format('YYYY-MM-DD')
                        }, header)
                        if (response?.status == 200) {
                            Swal.fire(
                                'Request Placed!',
                                'Your request has been successfully placed.',
                                'success'
                            );
                            setWithdrawPrice(0);
                            getWithdrawList();
                        }
                    }
                });
            }
            catch (e) {
                console.log("Err::", e);
            }
        }
        else {
            Swal.fire('Enter currect amount!', 'For create withdraw request, enter currect amount.', 'danger');
        }
    }

    document.title = `Seller Wallet | ${siteConfig?.name}`;

    return (
        <div className='seller-wallet p-0'>
            <Container className='seller-wallet-main-container pb-5'>
                <h2 className='fw-bold py-4 m-0'>Payments</h2>
                <div className='seller-wallet-container p-2 p-sm-3'>
                    <Tabs
                        defaultActiveKey="transactions"
                        id="fill-tab-example"
                        className="mb-3 custom-tabs"
                        fill
                    >
                        <Tab eventKey="payment-details" title="Payment details">
                            <div className='py-2 pb-4 d-grid gap-3'>
                                <h5 className='fw-semibold m-0'>Printfuse  Balance</h5>
                                <div className='d-grid gap-1'>
                                    <div className='d-flex align-items-start align-items-sm-center gap-2'>
                                        <i className="bi bi-check-circle color-info"></i>
                                        <span className='fs-14 fs-sm-12'>Effortlessly cover order and production costs without any hassle</span>
                                    </div>
                                    <div className='d-flex align-items-start align-items-sm-center gap-2'>
                                        <i className="bi bi-check-circle color-info"></i>
                                        <span className='fs-14 fs-sm-12'>Streamlined order processing without the hassle of card payments</span>
                                    </div>
                                    <div className='d-flex align-items-start align-items-sm-center gap-2'>
                                        <i className="bi bi-check-circle color-info"></i>
                                        <span className='fs-14 fs-sm-12'>Avoid extra fees for transactions and currency conversions</span>
                                    </div>
                                </div>
                                <Container className='seller-wallet-payment pb-3'>
                                    <Row className='half-border-rad'>
                                        <Col className='seller-wallet-payment-sub p-3 col-12 col-md-6 d-grid d-md-none gap-1'>
                                            <div className='w-100 flex-center-align'>
                                                <div className='seller-wallet-payment-sub-img'>
                                                    <img src={require('../../Assets/Images/try-logo.png')} alt="" />
                                                </div>
                                            </div>
                                            <h4 className='fw-semibold fs-md-18 m-0'>Printfuse Balance</h4>
                                            <h6 className='fw-medium fs-14'>Trusted by 25K+ merchants</h6>
                                        </Col>
                                        <Col className='seller-wallet-payment-main p-3 col-12 col-md-6 '>
                                            <h6 className='fw-semibold fs-14 '>Current Balance</h6>
                                            <h4 className='fw-semibold fs-md-18'>₹{commaNumber(user?.walletBalance) ?? 0}</h4>
                                            <h6 className='fw-semibold fs-14 '>Recharge with:</h6>

                                            {/*  */}
                                            {/* RazorPay Link */}
                                            {/*  */}
                                            <Link className='flex-center-align fw-semibold gap-2' onClick={() => setShowPrice(!showPrice)}><SiRazorpay />RazorPay</Link>
                                            <Modal
                                                show={showPrice}
                                                size="md"
                                                onHide={() => setShowPrice(!showPrice)}
                                                aria-labelledby="contained-modal-title-vcenter"
                                                centered>
                                                <Modal.Header closeButton>
                                                    <Modal.Title> <div className='fw-semibold fs-20 fs-md-16'><SiRazorpay className='color-info' /><span className='ps-2'>RazorPay</span></div> </Modal.Title>
                                                </Modal.Header>
                                                <Modal.Body>
                                                    <div className='d-grid'>
                                                        <label htmlFor="price">Enter Amount :</label>
                                                        <input
                                                            type="number"
                                                            className='py-2 px-3 seller-box'
                                                            autoComplete='off'
                                                            placeholder='enter min. ₹500'
                                                            id='price'
                                                            onChange={(e) => handlePrice(e.target.value)}
                                                        />
                                                    </div>
                                                </Modal.Body>
                                                <Modal.Footer className='d-grid' style={{ justifyContent: 'normal' }}>

                                                    <div className='d-grid gap-2'>
                                                        <Button variant="primary" className='fw-semibold' onClick={customToggle} disabled={(amount < 500)}>
                                                            Confirm
                                                        </Button>
                                                        <Button variant="dark" className='fw-semibold' onClick={() => setShowPrice(!showPrice)}>
                                                            Close
                                                        </Button>
                                                    </div>

                                                </Modal.Footer>
                                            </Modal>
                                        </Col>
                                        <Col className='seller-wallet-payment-sub p-3 col-12 col-md-6 d-none d-md-grid gap-1'>
                                            <div className='w-100 flex-center-align'>
                                                <div className='seller-wallet-payment-sub-img'>
                                                    <img src={require('../../Assets/Images/try-logo.png')} alt="" />
                                                </div>
                                            </div>
                                            <h4 className='fw-semibold fs-md-18 m-0'>Printfuse Balance</h4>
                                            <h6 className='fw-medium fs-14'>Trusted by 25K+ merchants</h6>
                                        </Col>
                                    </Row>
                                </Container>
                                <div className='seller-wallet-card pb-4 d-grid'>
                                    <h5 className='fw-semibold'>Payment Card</h5>
                                    <p className='fs-14 fs-sm-12'>Add a credit/debit card for use when there are not enough funds in your Printfuse balance..</p>
                                    <Link className='fw-semibold d-flex gap-2' onClick={() => setShow(!show)}><i className="bi bi-plus-lg"></i>Add a credit/debit card</Link>
                                    <Modal
                                        show={show}
                                        onHide={() => setShow(!show)}
                                        backdrop="static"
                                        keyboard={false}
                                        className='seller-wallet-card'
                                    >
                                        <Modal.Header closeButton>
                                            <Modal.Title> <span className='fw-semibold fs-18'>Add a credit/debit card to your account</span> </Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body className='custom-trial-modal'>
                                            <FormComponent
                                                action={frontEnd_API.signup}
                                                submitFullWidth={true}
                                                fields={[
                                                    {
                                                        name: 'card number',
                                                        key: 'cardNumber',
                                                        placeholder: '0000 0000 0000 0000',
                                                        required: true,
                                                        pattern: pattern.cardNumber?.ptr,
                                                        mask: pattern.cardNumber?.mask
                                                    },
                                                    {
                                                        divClass: 'col-12 col-lg-6',
                                                        name: 'Expiry date',
                                                        key: 'expiryDate',
                                                        placeholder: 'MM/YY',
                                                        required: true,
                                                        pattern: pattern.expiryDate?.ptr,
                                                        mask: pattern.expiryDate?.mask
                                                    },
                                                    {
                                                        divClass: 'col-12 col-lg-6',
                                                        name: 'Security code (CVV)',
                                                        key: 'cvvNumber',
                                                        placeholder: '***',
                                                        required: true,
                                                        pattern: pattern.cvvNumber,
                                                    },
                                                    {
                                                        divClass: 'col-12',
                                                        name: 'Cardholder name',
                                                        key: 'cardHolderName',
                                                        placeholder: 'Name written on the card',
                                                        required: true,
                                                        pattern: pattern.cardHolderName,
                                                    },
                                                    {
                                                        divClass: 'col-12 col-lg-6',
                                                        name: 'Country',
                                                        key: 'country',
                                                        required: true,
                                                    },
                                                    {
                                                        divClass: 'col-12 col-lg-6',
                                                        name: 'State / Province',
                                                        key: 'state',
                                                        required: true,
                                                    },
                                                    {
                                                        name: 'Address 1',
                                                        key: 'address1',
                                                        required: true,
                                                    },
                                                    {
                                                        name: 'Address 2',
                                                        key: 'address2',
                                                        required: false,
                                                    },
                                                    {
                                                        divClass: 'col-12 col-lg-6',
                                                        name: 'City',
                                                        key: 'city',
                                                        required: true,
                                                    },
                                                    {
                                                        divClass: 'col-12 col-lg-6',
                                                        name: 'ZIP code',
                                                        key: 'ZIPCode',
                                                        required: true,
                                                        pattern: pattern.zipCode
                                                    },
                                                ]}
                                            />
                                        </Modal.Body>
                                    </Modal>
                                </div>
                                <div className='d-grid d-md-flex gap-4 gap-md-2'>
                                    <div className='seller-wallet-prm w-100'>
                                        <h5 className='fw-semibold'>Printfuse  Premium</h5>
                                        <p className='fs-14 fs-sm-12'>Earn more with Printfuse Premium. Subscribe today to get up to 20% discount on all products, plus a one-time bonus of in Printfuse credit.</p>
                                        <Link className='fw-semibold fs-sm-14'>Subscribe</Link>
                                    </div>
                                    <div className='seller-wallet-prm w-100 d-grid align-content-between'>
                                        <h5 className='fw-semibold'>Coupons</h5>
                                        <p className='fs-14 fs-sm-12'>Add and store your coupon codes here.</p>
                                        <div className='d-flex gap-2 align-items-center'>
                                            <input type="text" placeholder='Enter Code' className='fs-sm-14 w-100 seller-box p-2' />
                                            <Link className='fw-semibold fs-sm-14'>Apply</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Tab>
                        <Tab eventKey="transactions" title="Transactions">
                            <div className='d-grid gap-2'>
                                <div className='py-2 d-grid gap-3 custom-btn-scrollbar'>
                                    {/* Search Button */}
                                    <div className='d-flex align-items-center gap-2 d-md-grid'>
                                        <Container className='custom-seller-category-search px-0'>
                                            <div className='d-flex align-items-center gap-2 gap-lg-3 px-2 px-lg-3'>
                                                <label className='bi bi-search' htmlFor='text'></label>
                                                <input
                                                    type="text"
                                                    className='py-2 fs-sm-14 pe-2'
                                                    id='text'
                                                    placeholder='Search by product name or description'
                                                    autoComplete='off'
                                                    onChange={(e) => {
                                                        setFilters((prevData) => ({
                                                            ...prevData,
                                                            search: e?.target?.value
                                                        }))
                                                    }}
                                                />
                                            </div>
                                        </Container>
                                        <div className='d-block d-md-none'
                                            style={{ cursor: 'pointer' }}
                                            onClick={() => setShowFilter(!showFilter)}
                                        ><i className="bi bi-funnel-fill px-3 py-2 seller-box"></i></div>


                                        {/* Filters */}
                                        <Modal
                                            show={showFilter}
                                            onHide={() => setShowFilter(!showFilter)}
                                            backdrop="static"
                                            keyboard={false}
                                        >
                                            <Modal.Header closeButton>
                                                <Modal.Title>Filter Invoices</Modal.Title>
                                            </Modal.Header>
                                            <Modal.Body>
                                                <div className='d-grid gap-3'>
                                                    {
                                                        (filterList?.parentData?.length > 0) &&
                                                        filterList?.parentData?.map((item, index) => {
                                                            return (
                                                                <div key={index} className={`d-grid ${(filterList?.parentData?.length == 1) ? 'w-25' : 'w-100'} custom-select-option`}>
                                                                    <Dropdown className='d-inline w-100' autoClose="outside">
                                                                        <Dropdown.Toggle variant="light" id="dropdown-autoclose-outside" className='seller-box no-border-rad text-start w-100 fw-semibold text-capitalize'>
                                                                            {item.title}
                                                                        </Dropdown.Toggle>
                                                                        <Dropdown.Menu className='w-100 gap-1 check-holder'>
                                                                            {
                                                                                item?.data.map((subItem, subIndex) => {
                                                                                    return (
                                                                                        <Dropdown.Item key={subIndex} as={'div'} className='flex-between-align gap-1 gap-md-2 justify-content-start w-100'>
                                                                                            <input type="checkbox"
                                                                                                className='ui-checkbox'
                                                                                                name={subItem.label}
                                                                                                id={subItem.label}
                                                                                                value={subItem.value}
                                                                                                checked={filters[item?.title]?.includes(subItem.value)}
                                                                                                onChange={() => handleCheckTransaction({
                                                                                                    value: subItem?.value,
                                                                                                    title: item?.title
                                                                                                })} />

                                                                                            <label htmlFor={subItem.label} className='text-capitalize fs-18 fs-lg-14 fs-sm-12'>{subItem.label}</label>
                                                                                        </Dropdown.Item>
                                                                                    )
                                                                                })
                                                                            }
                                                                        </Dropdown.Menu>
                                                                    </Dropdown>
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </div>
                                            </Modal.Body>
                                            <Modal.Footer>
                                                <Button variant="secondary" onClick={() => setShowFilter(false)}>
                                                    Close
                                                </Button>
                                                <Button variant="primary" onClick={() => setShowFilter(false)}>Apply Filters</Button>
                                            </Modal.Footer>
                                        </Modal>
                                    </div>
                                    <div className='d-none d-md-flex'>
                                        {
                                            (filterList?.parentData?.length > 0) &&
                                            filterList?.parentData?.map((item, index) => {
                                                return (
                                                    <div key={index} className={`d-grid ${(filterList?.parentData?.length == 1) ? 'w-25' : 'w-100'} custom-select-option`}>
                                                        <Dropdown className='d-inline w-100' autoClose="outside">
                                                            <Dropdown.Toggle variant="light" id="dropdown-autoclose-outside" className='seller-box no-border-rad text-start w-100 fw-semibold text-capitalize'>
                                                                {item.title}
                                                            </Dropdown.Toggle>
                                                            <Dropdown.Menu className='w-100 gap-1 check-holder'>
                                                                {
                                                                    item?.data.map((subItem, subIndex) => {
                                                                        return (
                                                                            <Dropdown.Item key={subIndex} as={'div'} className='flex-between-align gap-1 gap-md-2 justify-content-start w-100'>
                                                                                <input type="checkbox"
                                                                                    className='ui-checkbox'
                                                                                    name={subItem.label}
                                                                                    id={subItem.label}
                                                                                    value={subItem.value}
                                                                                    checked={filters[item?.title]?.includes(subItem.value) || (filters[item?.title]?.length - 1) == filterList?.parentData[item?.title]?.length}
                                                                                    onChange={() => handleCheckTransaction({
                                                                                        value: subItem?.value,
                                                                                        title: item?.title
                                                                                    })} />

                                                                                <label htmlFor={subItem.label} className='text-capitalize fs-18 fs-lg-14 fs-sm-12'>{subItem.label}</label>
                                                                            </Dropdown.Item>
                                                                        )
                                                                    })
                                                                }
                                                            </Dropdown.Menu>
                                                        </Dropdown>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                                {
                                    (transactionData?.length > 0) ? (
                                        <div className='pb-2'>
                                            <div className='custom-transaction-tab d-grid text-start text-capitalize'>
                                                {/* <h5 className='fw-semibold'>Transaction History</h5> */}
                                                {/* <hr className='m-0' /> */}
                                                <div className='d-grid gap-2 gap-sm-3'>
                                                    <div className='custom-withdrawlist g-0 d-grid'>
                                                        <div className='g-0 p-0 col-12 d-none d-md-grid'>
                                                            <Row className='flex-between-align custom-withdrawlist-item py-3 px-3 fw-semibold text-capitalize'>
                                                                <Col className='col-8 d-grid d-md-flex gap-2'>
                                                                    <span style={{ minWidth: '125px' }}>Date</span>
                                                                    <span className='w-100'>particular</span>
                                                                </Col>
                                                                <Col className='col-2'>module</Col>
                                                                <Col className='col-1'>debit</Col>
                                                                <Col className='col-1'>credit</Col>
                                                            </Row>
                                                            <hr className={`m-0`} />
                                                        </div>
                                                        {
                                                            transactionData.map((item, index) => (
                                                                <div key={index} className='g-0 p-0 col-12 d-grid'>
                                                                    <Row className='flex-between-align custom-withdrawlist-item py-3 py-md-2 px-3 gy-2 gy-md-2'>
                                                                        <Col className='col-12 col-md-8 d-grid d-md-flex gap-2 fs-14 fs-md-12 fs-sm-10'>
                                                                            <div className='flex-between-align gap-2' style={{ minWidth: '125px' }}>
                                                                                <div>
                                                                                    <span className='d-md-none fw-semibold'>Last Update on: </span>
                                                                                    {moment(item?.date).format('DD, MMM YYYY')}
                                                                                </div>
                                                                                <div className='d-flex d-md-none align-items-center justify-content-end justify-content-md-start'>
                                                                                    <Alert
                                                                                        className='m-0 text-center fw-semibold py-1 px-2 fs-12 fs-sm-10'
                                                                                        variant={
                                                                                            (item?.amount >= 0) ? "success" :
                                                                                                (item?.amount <= -1) ? "danger" :
                                                                                                    "secondary"
                                                                                        }
                                                                                    >
                                                                                        {item?.walletType}
                                                                                    </Alert>

                                                                                </div>
                                                                            </div>
                                                                            <div className='d-grid gap-1'>
                                                                                <span className='d-md-none fw-semibold'>Particular: </span>
                                                                                {item?.particular}
                                                                            </div>
                                                                        </Col>
                                                                        <Col className='col-6 col-md-2 d-none d-md-grid'>
                                                                            <div className='d-flex align-items-center justify-content-end justify-content-md-start'>
                                                                                <Alert
                                                                                    className='m-0 text-center fw-semibold py-1 px-2 fs-12 fs-sm-10'
                                                                                    variant={
                                                                                        (item?.amount >= 0) ? "success" :
                                                                                            (item?.amount <= -1) ? "danger" :
                                                                                                "secondary"
                                                                                    }
                                                                                >
                                                                                    {item?.walletType}
                                                                                </Alert>
                                                                            </div>
                                                                        </Col>
                                                                        <Col className='col-6 col-md-1 fs-14 d-flex gap-1 fw-semibold'>
                                                                            <span className='d-md-none'>Debit : </span>
                                                                            <span>
                                                                                {item?.amount <= -1 ? item?.amount : 0}
                                                                            </span>
                                                                        </Col>
                                                                        <Col className='col-6 col-md-1 fs-14 d-flex gap-1 fw-semibold'>
                                                                            <span className='d-md-none'>Credit : </span>
                                                                            <span>
                                                                                {item?.amount >= 0 ? item?.amount : 0}
                                                                            </span>
                                                                        </Col>
                                                                    </Row>
                                                                    <hr className={`m-0 ${(withdrawList?.length == index + 1) ? "d-none" : ''}`} />
                                                                </div>
                                                            ))
                                                        }
                                                    </div>
                                                    <CommonPagination
                                                        totalCount={totalCount}
                                                        changePage={(data) => {
                                                            setFilters((prevData) => ({
                                                                ...prevData,
                                                                limit: data?.limit,
                                                                page: data?.page
                                                            }))
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    ) : (
                                        <div className='py-2 flex-center-align py-5'>
                                            <div className='custom-transaction-tab d-grid justify-content-center text-center'>
                                                <div>
                                                    <GiCactusPot />
                                                </div>
                                                <h5 className='fw-semibold'>No transactions have yet been made</h5>
                                            </div>
                                        </div>
                                    )
                                }
                            </div>
                        </Tab>
                        {/* <Tab eventKey="order-invoices" title="Order Invoices">
                            <div className='py-2 d-grid gap-3 custom-btn-scrollbar'>
                                <div>
                                    <h5 className='fw-semibold'>Order invoices</h5>
                                    <p className='p-color m-0 fs-14 fs-sm-12'>Per order invoicing provides individual invoices for each order. You can choose the frequency and format of your invoices by switching between Summary and Per order invoicing from the Settings page .</p>
                                </div>
                                <div className='py-5'>
                                    <div className='custom-transaction-tab d-grid justify-content-center text-center gap-2'>
                                        <div>
                                            <GiNotebook />
                                        </div>
                                        <h5 className='fw-semibold'>No invoices yet</h5>
                                        <p className='p-color fs-12'>Invoices will appear here after you fulfill orders.</p>
                                    </div>
                                </div>
                            </div>
                        </Tab> */}
                        <Tab eventKey="withdrawals" title="Withdrawals">
                            <div className='py-2'>
                                <div className='d-grid gap-2'>
                                    <h5 className='fw-semibold m-0'>Withdraw Balance</h5>
                                    <p className='p-color fs-15 fs-sm-12 m-0'>Withdraw money from your balance. Your request will be reviewed within next 48 business hours and you will receive email once it has been processed.</p>
                                    <div className='custom-withdraw-tab-balance d-grid gap-4 gap-sm-3 gap-md-0 d-md-flex justify-content-center justify-content-md-between px-2 px-sm-3 py-4 text-start'>
                                        <div>
                                            <div className='d-grid gap-2 align-content-start'>
                                                <h6 className='fw-semibold m-0 fs-14'>Current balance</h6>
                                                <h3 className='fw-bold m-0'>₹{commaNumber(user?.walletBalance) ?? 0}</h3>
                                            </div>
                                        </div>
                                        <div className='d-grid'>
                                            <h6 className='mb-1 mb-sm-0 fs-14 fw-semibold'>Withdraw amount:</h6>
                                            <div className='d-grid d-md-flex align-items-center gap-2 gap-sm-3'>
                                                <div className='custom-rupee-input d-grid align-content-start'>
                                                    <div className='fw-semibold d-flex gap-1 fs-sm-14 p-1 p-sm-2'>
                                                        <label htmlFor='rupee'>₹</label>
                                                        <input
                                                            type="number"
                                                            id='rupee'
                                                            className='fw-semibold'
                                                            value={withdrawPrice}
                                                            placeholder='Enter Amount'
                                                            onChange={(e) => setWithdrawPrice(e.target.value)}
                                                        />
                                                    </div>
                                                </div>
                                                <div className='d-grid text-center'>
                                                    <Link
                                                        className='custom-rupee-btn fw-semibold fs-sm-14 py-1 px-2 py-sm-2 px-sm-3'
                                                        onClick={() => handleWithdraw()}
                                                    >
                                                        Send Request
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='pt-4 pb-2 py-sm-5  d-grid gap-3 gap-sm-4'>
                                    <h4 className='fw-semibold m-0 px-2 fs-sm-20'>Withdraw history</h4>
                                    {
                                        withdrawList?.length > 0 ?
                                            <div className='px-2'>
                                                <div className='custom-withdrawlist g-0 d-grid'>
                                                    <div className='g-0 p-0 col-12 d-none d-md-grid'>
                                                        <Row className='flex-between-align custom-withdrawlist-item py-3 px-3 fw-semibold'>
                                                            <Col className='col-2'>Date</Col>
                                                            <Col className='col-2'>Amount</Col>
                                                            <Col className='col-2'>Status</Col>
                                                            <Col className='col-6'>Remark</Col>
                                                        </Row>
                                                        <hr className={`m-0`} />
                                                    </div>
                                                    {
                                                        withdrawList.map((item, index) => (
                                                            <div key={index} className='g-0 p-0 col-12 d-grid'>
                                                                <Row className='flex-between-align custom-withdrawlist-item py-3 py-md-2 px-3 gy-2 gy-md-2'>
                                                                    <Col className='col-12 col-md-2 fs-14 fs-md-12 fs-sm-10'>
                                                                        <span className='d-md-none fw-semibold'>Last Update on: </span>
                                                                        {moment(item?.date).format('DD, MMM YYYY')}
                                                                    </Col>
                                                                    <Col className='col-6 col-md-2 fs-16 fs-md-14 fw-semibold'>
                                                                        <span className='d-md-none fw-semibold'>Amt. : </span>
                                                                        ₹{commaNumber(item?.amount)}
                                                                    </Col>
                                                                    <Col className='col-6 col-md-2'>
                                                                        <div className='d-flex align-items-center justify-content-end justify-content-md-start'>
                                                                            <Alert
                                                                                className='m-0 text-center fw-semibold py-1 px-2 fs-14 fs-sm-12'
                                                                                variant={
                                                                                    item?.withDrawStatus === "REQUEST" ? "primary" :
                                                                                        item?.withDrawStatus === "ACCEPT" ? "success" :
                                                                                            item?.withDrawStatus === "ONHOLD" ? "warning" :
                                                                                                item?.withDrawStatus === "COMPLETE" ? "info" :
                                                                                                    item?.withDrawStatus === "CANCEL" ? "danger" :
                                                                                                        item?.withDrawStatus === "PENDING" ? "secondary" :
                                                                                                            "secondary"
                                                                                }
                                                                            >
                                                                                {item?.withDrawStatus}
                                                                            </Alert>

                                                                        </div>
                                                                    </Col>
                                                                    <Col className='col-12 col-md-6 fs-14 fs-md-12 fs-sm-10 d-grid gap-1'>
                                                                        <span className='d-md-none fw-semibold'>Remark : </span>
                                                                        <span>
                                                                            {item?.remark}
                                                                        </span>
                                                                    </Col>
                                                                </Row>
                                                                <hr className={`m-0 ${(withdrawList?.length == index + 1) ? "d-none" : ''}`} />
                                                            </div>
                                                        ))
                                                    }
                                                </div>
                                                <div className='g-0 p-0 d-grid py-3'>
                                                    <CommonPagination
                                                        totalCount={withdrawTotalList}
                                                        changePage={(data) => {
                                                            setWithdrawFilters((prevData) => ({
                                                                ...prevData,
                                                                limit: data?.limit,
                                                                page: data?.page
                                                            }))
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            : <div className='custom-transaction-tab d-grid gap-2 justify-content-center text-center py-5'>
                                                <div>
                                                    <TbTransactionRupee />
                                                </div>
                                                <h5 className='fw-semibold fs-sm-12'>No transactions have yet been made</h5>
                                            </div>
                                    }
                                </div>
                            </div>
                        </Tab>
                        <Tab eventKey="taxes" title="Taxes">
                            <div className='py-2'>
                                <div className="taxes-page d-grid gap-3">
                                    <h2 className='fw-bold'>GST in India</h2>

                                    <section>
                                        <h4 className='fw-bold'>Introduction to GST</h4>
                                        <ul>
                                            <li>The Goods and Services Tax (GST) is an indirect tax used in India on the supply of goods and services.</li>
                                            <li>It is a comprehensive, multi-stage, destination-based tax.</li>
                                            <li>GST is categorized into CGST (Central GST), SGST (State GST), and IGST (Integrated GST).</li>
                                        </ul>
                                    </section>

                                    <section>
                                        <h4 className='fw-bold'>GST Rates for Different Products</h4>
                                        <ul>
                                            <li>
                                                <h6 className='fw-bold'>Clothing</h6>
                                                <ul>
                                                    <li>Garments and Apparel (below ₹1000): 5%</li>
                                                    <li>Garments and Apparel (above ₹1000): 12%</li>
                                                    <li>Fabric: 5%</li>
                                                </ul>
                                            </li>
                                            <li>
                                                <h6 className='fw-bold'>Mugs</h6>
                                                <ul>
                                                    <li>Ceramic mugs: 12%</li>
                                                    <li>Glass mugs: 18%</li>
                                                </ul>
                                            </li>
                                        </ul>
                                    </section>

                                    <section>
                                        <h4 className='fw-bold'>State-wise GST Variations</h4>
                                        <ul>
                                            <li>
                                                <h6 className='fw-bold'>Maharashtra</h6>
                                                <ul>
                                                    <li>Standard GST rates apply.</li>
                                                    <li>Additional requirements: E-way bill for movement of goods.</li>
                                                </ul>
                                            </li>
                                            <li>
                                                <h6 className='fw-bold'>Karnataka</h6>
                                                <ul>
                                                    <li>Standard GST rates apply.</li>
                                                    <li>Additional requirements: Timely filing of GST returns to avoid penalties.</li>
                                                </ul>
                                            </li>
                                            <li>
                                                <h6 className='fw-bold'>Delhi</h6>
                                                <ul>
                                                    <li>Standard GST rates apply.</li>
                                                    <li>Additional requirements: Local compliance checks.</li>
                                                </ul>
                                            </li>
                                        </ul>
                                    </section>

                                    <section>
                                        <h4 className='fw-bold'>How GST is Applied</h4>
                                        <ul>
                                            <li>
                                                <h6 className='fw-bold'>Product-wise Application</h6>
                                                <ul>
                                                    <li>GST is applied based on the type of product as mentioned above.</li>
                                                    <li>For clothing, different rates are applied based on the value of the item.</li>
                                                    <li>For mugs, the material of the mug determines the GST rate.</li>
                                                </ul>
                                            </li>
                                            <li>
                                                <h6 className='fw-bold'>State-wise Compliance</h6>
                                                <ul>
                                                    <li>Ensure that the correct GST is charged based on the destination state.</li>
                                                    <li>Follow any additional state-specific compliance requirements.</li>
                                                </ul>
                                            </li>
                                        </ul>
                                    </section>

                                    <section>
                                        <h4 className='fw-bold'>How to Calculate GST</h4>
                                        <ul>
                                            <li>
                                                <h6 className='fw-bold'>Example for Clothing</h6>
                                                <ul>
                                                    <li>If a shirt costs ₹800, GST at 5% = ₹40.</li>
                                                    <li>If a shirt costs ₹1500, GST at 12% = ₹180.</li>
                                                </ul>
                                            </li>
                                            <li>
                                                <h6 className='fw-bold'>Example for Mugs</h6>
                                                <ul>
                                                    <li>If a ceramic mug costs ₹200, GST at 12% = ₹24.</li>
                                                    <li>If a glass mug costs ₹200, GST at 18% = ₹36.</li>
                                                </ul>
                                            </li>
                                        </ul>
                                    </section>

                                    <section>
                                        <h4 className='fw-bold'>Filing GST</h4>
                                        <ul>
                                            <li>Monthly and Annual Returns: Regular filing of GSTR-1, GSTR-3B, and annual GSTR-9.</li>
                                            <li>E-way Bill: Required for the transportation of goods worth more than ₹50,000.</li>
                                        </ul>
                                    </section>

                                    <section>
                                        <h4 className='fw-bold'>Frequently Asked Questions (FAQs)</h4>
                                        <ul>
                                            <li>
                                                <h6 className='fw-bold'>What is the penalty for late filing?</h6>
                                                <p>Penalty is ₹100 per day under CGST and SGST each (total ₹200 per day).</p>
                                            </li>
                                            <li>
                                                <h6 className='fw-bold'>How to claim a GST refund?</h6>
                                                <p>File GST RFD-01 for claiming refunds.</p>
                                            </li>
                                        </ul>
                                    </section>

                                    <section>
                                        <h4 className='fw-bold'>Contact Information</h4>
                                        <p>For any queries, contact our customer support at [contact details].</p>
                                    </section>
                                </div>
                            </div>
                        </Tab>
                        {/* <Tab eventKey="rewards" title="Rewards">
                            <div className='py-2 flex-center-align py-5'>
                                <div className='custom-reward-tab d-grid justify-content-center text-center'>
                                    <div>
                                        <CiGift />
                                    </div>
                                    <h5 className='fw-semibold'>No rewards have been received yet</h5>
                                </div>
                            </div>
                        </Tab> */}
                    </Tabs>

                </div>
            </Container >
            <ToastContainer />
        </div >
    )
}

export default SellerWallet