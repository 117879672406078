import React, { useEffect, useState } from 'react';
import { Col, Container, Dropdown, Row } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { GiProfit } from "react-icons/gi";
import { IoShirtSharp } from "react-icons/io5";
import { CiShare1 } from "react-icons/ci";
import { HiOutlineSwitchVertical } from "react-icons/hi";
import { IoSettingsSharp } from "react-icons/io5";
import Modal from 'react-bootstrap/Modal';
import { ProductSliderList } from '../../Data/localData';
import CommonSlider from '../../Components/CommonSlider';
import { useDispatch, useSelector } from 'react-redux';
import { frontEnd_API, header, SERVER_URL, siteConfig } from '../../Config/Config';
import axios from 'axios';
import { addStore, updateStore } from '../../Store/Slices/StoreSlice';
import { updateUser } from '../../Store/Slices/UserSlice';

function SellerDashboard() {

  const [show, setShow] = useState(false);
  const toggleShow = () => setShow(!show);
  const userData = useSelector((state) => state.user.value);
  const storeData = useSelector((state) => state.store.value);
  const [store, setStore] = useState([]);
  const [dashboard, setDashboard] = useState({});
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    if (userData?.storeId === 0) {
      navigate('/create-store');
    }
  }, [userData, navigate])

  useEffect(() => {
    axios.get(frontEnd_API.getStore, header)
      .then((res) => {
        setStore(res?.data?.data)
        console.log(res?.data?.data)
      })
      .catch((err) => {
        console.log("err::", err);
      })

  }, [])

  useEffect(() => {
    const fetchData = async () => {
      if (!storeData) {
        if (store.length !== 0) {
          let val = store.length - 1;
          try {
            const { data } = await axios.get(`${frontEnd_API.getSingleStore}/${store[val].id}`, header);
            console.log(data);
            dispatch(addStore(data?.data));
            dispatch(updateUser({ storeCode: data?.data?.storeCode }))
          } catch (e) {
            console.log(e);
          }
          window.location.reload();
        }
      }
    };

    fetchData();
  }, [storeData, store, dispatch])

  const customContainClick = async (item) => {
    toggleShow();
    console.log(item);
    try {
      const { data } = await axios.get(`${frontEnd_API.getSingleStore}/${item.id}`, header)
      console.log(data);
      dispatch(updateStore(data?.data));
      dispatch(updateUser({ storeCode: data?.data?.storeCode }))
      window.location.reload();
    }
    catch (e) {
      console.log(e);
    }
  }

  const date = new Date();
  const hour = date.getHours();
  let time = '';

  if (hour >= 0 && hour <= 11) {
    time = 'Good Morning';
  }
  else if (hour >= 12 && hour <= 17) {
    time = 'Good Afternoon';
  }
  else if (hour >= 18 && hour <= 23) {
    time = 'Good Evening';
  }

  const getDashboardData = async () => {
    try {
      const { data } = await axios.get(frontEnd_API.dashboard, header)
      console.log("data::", data);
      setDashboard(data?.data);
    }
    catch (e) {
      console.log("e::", e);
    }
  }

  useEffect(() => {
    getDashboardData();
    console.log("storeData::", storeData);
  }, [storeData?.id]);

  document.title = `Seller Dashboard | ${siteConfig?.name}`;

  return (
    <div className='p-3' style={{ backgroundColor: '#f7f7f7' }}>

      <Container className='py-3 px-sm-0'>
        <div className='d-grid gap-2 gap-sm-0 d-sm-flex align-items-center justify-content-sm-between'>
          <h3 className='m-0'>{time} <span className='fw-semibold text-capitalize'>{userData?.name}!</span></h3>
          <Dropdown>
            <Dropdown.Toggle className='fw-semibold custom-store-btn w-100' id="dropdown-basic">
              Quick Action
            </Dropdown.Toggle>

            <Dropdown.Menu className='w-100'>
              <Dropdown.Item as={Link} className='link-to-normal' to={'/create-store'}>Create Store</Dropdown.Item>
              <Dropdown.Item as={Link} className='link-to-normal' to={'/orders'}>Create Order</Dropdown.Item>
              <Dropdown.Item as={Link} className='link-to-normal' to={'/products'}>Add Products</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </Container>

      {/* Store Details Banner */}
      <Container className='px-2 px-lg-0 pb-4'>
        <div className='d-grid d-sm-flex justify-content-center justify-content-sm-between seller-box p-3 gap-3 gap-sm-0'>
          <div className='flex-between-align gap-2'>
            <div className='logo-image-container'>
              <img src={(storeData?.logo) ? SERVER_URL + storeData?.logo : require('../../Assets/Images/try-logo.png')} alt="" />
            </div>
            <div className='d-flex align-content-center gap-2'>
              <div className='d-grid'>
                <h4 className='m-0 fw-semibold text-capitalize'>{storeData?.name}</h4>
                <Link className='fs-14 fs-md-12 fw-semibold' to={`https://${storeData?.domain}`} target='_blanck'>{storeData?.domain}<CiShare1 /></Link>
              </div>
              <div className='custom-setting-btn'>
                <Link to={'/store-setting'}><IoSettingsSharp /></Link>
              </div>
            </div>
          </div>
          <div className='d-flex justify-content-center align-items-center custom-store-dtl-banner gap-3'>
            <Link className={`custom-store-dtl-banner-switch flex-center-align w-100 gap-1 ${(show) && 'custom-css-toggle'}`} onClick={toggleShow}>
              Switch <HiOutlineSwitchVertical />
            </Link>
            <Modal show={show} onHide={toggleShow} className='custom-select-store-modal' aria-labelledby="contained-modal-title-vcenter" centered>
              <Modal.Header closeButton>
                <Modal.Title>Select Store</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className='d-grid gap-2'>
                  {
                    store.map((item, index) => (
                      <Link
                        key={index}
                        className={`custom-store-modal flex-center-align p-2 gap-2 ${(item.id === storeData?.id) && 'custom-store-modal-selected'}`}
                        onClick={() => customContainClick(item)}
                      >
                        <div className='custom-image-container-modal'>
                          <img src={(item.logo) ? SERVER_URL + item.logo : require('../../Assets/Images/try-logo.png')} alt="" />
                        </div>
                        <div className='d-grid '>
                          <h4 className=' m-0 text-capitalize'>{item.name}</h4>
                          <span className='fs-12 fs-md-12'>Domain : {item.domain}</span>
                        </div>
                      </Link>
                    ))
                  }
                </div>
              </Modal.Body>
            </Modal>
          </div>
        </div>
      </Container>

      {/* Order Counting part */}
      <Container className='order-cnt-tab'>
        <Row className='gy-3 gy-xl-0'>
          <Col className='custom-counting-part col-12 col-sm-6 col-lg-4 col-xl-3 px-2 px-lg-2 ps-lg-0'>
            <div className='custom-order-tab-content p-2 d-grid'>
              <div className='custom-order-tab-header flex-between-align'>
                <div className='flex-between-align gap-2 gap-md-3'>
                  <i className="bi bi-border-style"></i>
                  <h5 className="m-0 fs-lg-18 fs-md-16 fs-sm-14 fw-semibold">Total Order</h5>
                </div>
                <Link className="bi bi-info-circle">
                  <div className='custom-order-tab-info'>
                    total number of orders
                  </div>
                </Link>
              </div>
              <hr className='my-1' />
              <div className='custom-order-tab-sub-cnt'>
                <h3 className='fw-semibold mt-2 mt-sm-3'>{(dashboard?.countBox?.totalOrder) ?? 0}</h3>
                <div className='d-flex justify-content-end'>
                  <Link to={'/orders'} className='text-capitalize fw-semibold'>Go to Orders</Link>
                </div>
              </div>
            </div>
          </Col>
          <Col className='custom-counting-part col-12 col-sm-6 col-lg-4 col-xl-3 px-2 px-lg-2'>
            <div className='custom-order-tab-content p-2 d-grid'>
              <div className='custom-order-tab-header flex-between-align'>
                <div className='flex-between-align gap-2 gap-md-3'>
                  <GiProfit />
                  <h5 className="m-0 fs-lg-18 fs-md-16 fs-sm-14 fw-semibold">Total Profit</h5>
                </div>
                <Link className="bi bi-info-circle">
                  <div className='custom-order-tab-info'>
                    total profit till now
                  </div>
                </Link>
              </div>
              <hr className='my-1' />
              <div className='custom-order-tab-sub-cnt'>
                <h4 className='fw-semibold mt-2 mt-sm-3'>₹{(dashboard?.countBox?.totalProfit) ?? 0}</h4>
              </div>
            </div>
          </Col>
          <Col className='custom-counting-part col-12 col-sm-6 col-lg-4 col-xl-3 px-2 px-lg-2 pe-lg-0'>
            <div className='custom-order-tab-content p-2 d-grid'>
              <div className='custom-order-tab-header flex-between-align'>
                <div className='flex-between-align gap-2 gap-md-3'>
                  <i className="bi bi-wallet2"></i>
                  <h5 className="m-0 fs-lg-18 fs-md-16 fs-sm-14 fw-semibold">Wallet</h5>
                </div>
                <Link className="bi bi-info-circle">
                  <div className='custom-order-tab-info'>
                    current total remaining wallet balance
                  </div>
                </Link>
              </div>
              <hr className='my-1' />
              <div className='custom-order-tab-sub-cnt'>
                <h4 className='fw-semibold mt-2 mt-sm-3'>₹{(dashboard?.countBox?.walletBalance) ?? 0}</h4>
                <div className='d-flex justify-content-end'>
                  <Link to={'/wallet'} className='text-capitalize fw-semibold'>check Wallet</Link>
                </div>
              </div>
            </div>
          </Col>
          <Col className='custom-counting-part col-12 col-sm-6 col-lg-4 col-xl-3 px-2 px-lg-2 ps-lg-0 ps-lg-2 pe-lg-0'>
            <div className='custom-order-tab-content p-2 d-grid'>
              <div className='custom-order-tab-header flex-between-align'>
                <div className='flex-between-align gap-2 gap-md-3'>
                  <IoShirtSharp />
                  <h5 className="m-0 fs-lg-18 fs-md-16 fs-sm-14 fw-semibold">Total Products</h5>
                </div>
                <Link className="bi bi-info-circle">
                  <div className='custom-order-tab-info'>
                    total number of products that are created till now
                  </div>
                </Link>
              </div>
              <hr className='my-1' />
              <div className='custom-order-tab-sub-cnt'>
                <h4 className='fw-semibold mt-2 mt-sm-3'>{(dashboard?.countBox?.totalSellerProduct) ?? 0}</h4>
                {
                  (parseInt(dashboard?.countBox?.lastProductDay) !== 0) &&
                  <span className='text-secondary fs-14 fs-sm-12'>Last Update on {dashboard?.countBox?.lastProductDay} day ago</span>
                }
                <div className='d-flex justify-content-end'>
                  <Link to={'/products'} className='text-capitalize fw-semibold'>Add new Products</Link>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>

      {/* Banner */}
      <div className='py-4 px-2 px-sm-0'>
        <Container className='custom-seller-banner seller-box p-0 d-flex'>
          <div className='custom-seller-banner-gif'>
            <img src={require('../../Assets/Gif/Hello.gif')} alt="" />
          </div>
          <div className='custom-seller-banner-content d-grid align-content-between p-2 p-md-3 p-lg-4'>
            <h3 className='fw-semibold fs-md-20 mb-2 text-capitalize'>" {userData?.name} ! "</h3>
            <ul className='fs-sm-10 fs-md-12 mb-2'>
              <li>Create Unique Custom T-Shirts and Make a Statement!</li>
              <li className='custom-display-none'>Stand out from competition and build a customer base with the help of our AI powered Niche Generator in less than a minute</li>
              <li className='custom-display-none'>Express Yourself with Custom Tees!</li>
            </ul>
            <div className='d-flex justify-content-start gap-2'>
              <Link className='fs-sm-10 fs-md-12 p-1 px-2 text-center px-md-4 py-md-2'>Start creating store</Link>
              <Link className='fs-sm-10 fs-md-12 p-1 px-2 text-center px-md-4 py-md-2'>Start Designing Product</Link>
            </div>
          </div>
        </Container>
      </div>

      {/* BestSeller tab */}
      {
        (dashboard?.ourBestSellerData?.length > 0) &&
        <Container className='seller-box px-3'>

          <CommonSlider
            data={dashboard?.ourBestSellerData}
            fromAPI={true}
            title={'Our Bestsellers'}
            isPartition={true}
          />
        </Container>
      }

    </div >
  );
}

export default SellerDashboard;