import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { frontEnd_API } from '../../Config/Config';
import FormComponent from '../../Components/FormComponent';
import { storeUser } from '../../Store/Slices/UserSlice';
import { pattern } from '../../Config/Constant';
import { ToastContainer } from 'react-toastify';
import { useSelector } from 'react-redux';
import { PiStarFourFill } from "react-icons/pi";
import { CiAt } from "react-icons/ci";
import { IoDiamondOutline } from "react-icons/io5";


function SignupPage() {

    const localDomain = useSelector((state) => state.domain.value);

    return (
        <div>
            <Container fluid className='d-grid align-items-center custom-signin-page'>
                <Row className='custom-signin-page-row'>
                    <Col className='col-12 col-md-6 d-grid p-0'>
                        <div className='p-4 p-md-5 custom-signin-page-img'>
                            <div className='custom-img-dark-color' />
                            <div className='custom-bg-dark-color' />
                            <a href={"https://printfuse.in/"} className="logo-holder p-1">
                                <img src={require('../../Assets/Images/try-logo.png')} alt="" />
                            </a>
                            <div className='d-grid gap-4 content-holder'>
                                <h5 className='m-0 fw-semibold fs-sm-16 fs-md-18 '>
                                    “This software simplifies the website building process, making it a breeze to manage our online presence.”
                                </h5>
                                <div className='d-grid gap-1'>
                                    <h6 className='fw-semibold m-0 fs-sm-14 fs-md-16'>David Handerson</h6>
                                    <span className='fs-sm-12'>Founder & CEO</span>
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col className='col-12 col-md-6 d-grid p-0'>
                        <div className='px-3 px-lg-5 py-5 custom-signin-page-form'>
                            <Container className='cs-container-sm d-grid gap-4 px-xl-5 custom-signin-content'>
                                <div className='custom-sticky-icons d-none d-md-block'>
                                    <CiAt className='first-icon' />
                                    <PiStarFourFill className='second-icon' />
                                    <IoDiamondOutline className='third-icon' />
                                    <PiStarFourFill className='fourth-icon' />
                                </div>
                                <h1 className='fw-bold m-0 text-center'>Create an account</h1>
                                <div className='flex-center-align px-sm-5 gap-2 btn-list'>
                                    <button className='git-btn'><i className="bi bi-github"></i></button>
                                    <button className='google-btn'><i className="bi bi-google"></i></button>
                                </div>
                                <span className='text-center p-color'>OR</span>
                                <div className='d-grid px-md-5'>
                                    <FormComponent
                                        action={frontEnd_API.signup}
                                        dispatchRedux={storeUser}
                                        submitFullWidth={true}
                                        submitBTNAlign={'justify-content-start custom-submit-btn'}
                                        loadPage={true}
                                        formSubmit={"User Created Successfully !"}
                                        button={'Create my account'}
                                        navigatePageURL={(localDomain) && '/app/create-store'}
                                        fields={[
                                            {
                                                divClass: 'col-12',
                                                name: 'name',
                                                key: 'name',
                                                required: true,
                                                placeholder: 'Your name',
                                            },
                                            {
                                                divClass: 'col-12 col-md-6',
                                                name: 'email',
                                                key: 'email',
                                                placeholder: 'Your Email',
                                                required: true,
                                                pattern: pattern.email,
                                            },
                                            {
                                                divClass: 'col-12 col-md-6',
                                                name: 'mobile',
                                                key: 'mobile',
                                                type: 'number',
                                                placeholder: 'Enter 10 digit number',
                                                required: true,
                                                pattern: pattern.mobile,
                                            },
                                            {
                                                divClass: 'col-12',
                                                name: 'Password',
                                                key: 'password',
                                                type: 'password',
                                                required: true,
                                                placeholder: 'Enter strong password',
                                                pattern: pattern.password,
                                            }
                                        ]}
                                    />
                                </div>
                                <div className='d-grid gap-3 pt-2 px-md-5'>
                                    <p className='fs-10 m-0 text-center'>
                                        By clicking Sign up you agree to Printfuse Terms of Service , Privacy Policy and Intellectual Property Policy
                                    </p>
                                    <span className='fs-16 text-center'>
                                    Already Have an Account? <Link className='text-decoration-none color-temp fw-semibold' to={'/signin'}>Sign in</Link>
                                    </span>
                                </div>
                            </Container>
                        </div>
                    </Col>
                </Row>
            </Container>
            <ToastContainer />
        </div>
    )
}

export default SignupPage