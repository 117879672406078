import React, { useRef, useEffect } from 'react';
import html2canvas from 'html2canvas';
import { SERVER_URL } from '../../../Config/Config';
import moment from 'moment/moment';


export const generateImageFromData = (data, index) => {
    /*
    return new Promise((resolve) => {
        // Create a container element and set its HTML content
        const container = document.createElement('div');
        container.style.position = 'absolute'; // Ensure the container is in view
        container.style.top = '-9999px'; // Move the container off-screen

        const htmlContent = `
        <div style="position:'relative';height:'600px';width:'600px'">
            <img
                src='${SERVER_URL + data.preview}'
                style="height:'600px';width:'600px'"
            />
            <img
                src='${data.imgURL}'
                style="position:'absolute';top:'${data.ratioData.y / 5}%';left:'${data.ratioData.x / 5}%';height:${data.ratioData.height / 5}%;width:${data.ratioData.width / 5}%"
            />
        </div>`;
        
        container.innerHTML = htmlContent;

        // Append the container to the body
        document.body.appendChild(container);

        // Use html2canvas to capture the container element
        html2canvas(container).then(canvas => {
            // Convert canvas to Blob
            canvas.toBlob(blob => {
                const formData = new FormData();
                formData.append('file', blob, `image-${index}-${moment().format('YYMMDD')}.png`);

                // Clean up the container element
                document.body.removeChild(container);

                resolve(formData);
            });
        }).catch(error => {
            console.error('Error capturing canvas:', error);
            document.body.removeChild(container);
            resolve(new FormData());
        });
    });
    */
   
    return new Promise((resolve) => {
        // Create a new div element to hold the preview and images
        const div = document.createElement('div');
        div.style.position = 'relative';
        div.style.height = '600px'; // Adjust height/width as needed
        div.style.width = '600px';
        
        // Add the preview image as the background
        const previewImage = new Image();
        previewImage.src = SERVER_URL + data.preview;
        previewImage.style.height = '100%';
        previewImage.style.width = '100%';
        div.appendChild(previewImage);
        console.log('preview :: ', data?.preview)
        // Add the overlay image on top
        if (data?.imgURL) {
            const overlayImage = new Image();
            overlayImage.src = data.imgURL;
            overlayImage.style.position = 'absolute';
            overlayImage.style.top = `${data.ratioData.y / 5}%`;
            overlayImage.style.left = `${data.ratioData.x / 5}%`;
            overlayImage.style.height = `${data.ratioData.height / 5}%`;
            overlayImage.style.width = `${data.ratioData.width / 5}%`;
            div.appendChild(overlayImage);
        }

        // Append the div to the document body (it will be removed later)
        document.body.appendChild(div);

        // Generate image from the div using html2canvas
        html2canvas(div, {
            allowTaint: true,
            useCORS: true,
            backgroundColor: null,
            scale: 1.5,
        }).then((canvas) => {
            // Convert the canvas to base64
            const base64Image = canvas.toDataURL('image/png');

            // Clean up by removing the div
            document.body.removeChild(div);

            // Resolve the promise with the base64 image data
            resolve(base64Image);
        });        
    });
}