import React from 'react'
import { Container } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import Slider from 'react-slick'
import { TbPointFilled } from "react-icons/tb";
import { SERVER_URL } from '../Config/Config';

const custsettings = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 4,
  slidesToScroll: 1,
  initialSlide: 0,
  autoplay: false,
  autoplaySpeed: 0,
  responsive: [
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 3,
      }
    },
    {
      breakpoint: 576,
      settings: {
        slidesToShow: 1,
        autoplay: false,
        autoplaySpeed: 0,
      }
    }
  ]
};



function CommonSlider(props = {}) {
  console.log(props?.data);

  return (
    <Container className='custom-seller-category-slider-trending p-2 py-3 py-sm-4'>
      <div>
        <h4 className={`fw-semibold ${(!props?.description) && 'd-none'}`}>{props?.title}</h4>
        <p className='mb-0'>{props?.description}</p>
      </div>
      <div className=''>
        <h4 className={`fw-semibold ${(props?.description) && 'd-none'}`}>{props?.title}</h4>
        <Link className={`text-decoration-none ${(props?.seeClose) && 'd-none'}`}>See All</Link>
      </div>
      <hr className={(!props.isPartition) ? 'd-none' : "my-2"} />
      <Slider {...custsettings}>
        {
          props?.data.map((item, index) => {
            return (
              <Link to={((props?.fromAPI) ? `/product/${item?.id}` : '')}  key={index} className='text-decoration-none custom-slider-products'>
                <div className='slider-trending-container'>
                  <img src={((props?.fromAPI) ? SERVER_URL : '') + item.thumbnail} className='img-one' alt="" />
                  <img src={((props?.fromAPI) ? SERVER_URL : '') + item.thumbnailSlide} className='img-two' alt="" />
                </div>
                <div className='p-2 d-grid custom-slider-products-content gap-2'>
                  <div className='text-capitalize'>{item.name}</div>
                  <div className='d-grid gap-0'>
                    <span className='fw-semibold'>From ₹{item.productFromPrice}</span>
                    <span className='color-info fs-14 text-capitalize fw-meduim'>From ₹{Math.round(item.productFromPrice * 0.8)} with Printfuse premuim</span>
                  </div>
                  {/* <div className='d-flex justify-content-start gap-1 gap-sm-2 fs-14 fs-sm-10'>
                    <div className='d-flex justify-content-start'>
                      <i className="bi bi-star-fill"></i>
                      <i className="bi bi-star-fill"></i>
                      <i className="bi bi-star-fill"></i>
                      <i className="bi bi-star-fill"></i>
                      <i className="bi bi-star-half"></i>
                    </div>
                    <Link className=''>14</Link>
                  </div>
                  {
                    (item?.variation.data) &&
                    < div className='custom-slider-products-circle gap-1'>
                      {
                        item?.variation.data.map((subItem, subIndex) => {
                          return (
                            <span key={subIndex} style={{ backgroundColor: subItem }} />
                          )
                        })
                      }
                    </div>
                  } */}
                  <div className='fs-14 p-color'>
                    {
                      item?.variantData.map((vari, newIndex) => {
                        return (
                          <React.Fragment key={newIndex}>
                            <span>{vari.total} {vari.name}</span>
                            {(newIndex + 1) !== item.variantData.length && <TbPointFilled />}
                          </React.Fragment>
                        )
                      })
                    }
                  </div>
                </div>
              </Link>
            )
          })
        }
      </Slider>
    </Container>
  )
}

export default CommonSlider