import React, { useEffect, useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { FaPersonShelter } from "react-icons/fa6";
import FormComponent from '../../Components/FormComponent';
import { pattern } from '../../Config/Constant';
import axios from 'axios';
import { frontEnd_API, header, siteConfig } from '../../Config/Config';
import { updateUser } from '../../Store/Slices/UserSlice';
import { ToastContainer } from 'react-toastify';

function SellerAccount() {

  const [data, setData] = useState([]);
  const [val, setVal] = useState([]);

  useEffect(() => {
    axios.get(frontEnd_API.getGlobal, header)
      .then((res) => {
        setData(res?.data?.data);
        console.log(res?.data?.data);
      })
      .catch((err) => {
        console.log("err::", err);
      })

    axios.get(frontEnd_API.getProfile, header)
      .then((res) => {
        setVal(res?.data?.data);
        console.log("err::", res?.data?.data);
      })
      .catch((err) => {
        console.log("err::", err);
      })
  }, [])

  const updateProfile = () => {
    axios.get(frontEnd_API.getProfile, header)
      .then((res) => {
        setVal(res?.data?.data);
      })
      .catch((err) => {
        console.log("err::", err);
      })
  }

  document.title = `Seller Account | ${siteConfig?.name}`;

  return (
    <div className='p-2 seller-account'>
      <Container>
        <h3 className='py-4 m-0 fw-semibold'>My Account</h3>
        <Row className='seller-box py-4 px-0 px-sm-3'>
          <Col className='col-lg-2 d-none d-lg-flex flex-center-align align-items-start seller-account-img-holder'>
            <div className='flex-center-align'>
              <FaPersonShelter />
            </div>
          </Col>
          <Col className='col-12 col-lg-10'>
            <h5 className='fw-semibold m-0 pb-4'>Update Profile Details</h5>
            <FormComponent
              action={frontEnd_API.postUpdateProfile}
              dispatchRedux={updateUser}
              valueData={val}
              submitFullWidth={true}
              onSubmit={updateProfile}
              fields={[
                {
                  divClass: 'col-12',
                  name: 'name',
                  key: 'name',
                  required: true,
                },
                {
                  divClass: 'col-12 col-lg-6',
                  name: 'email',
                  key: 'email',
                  placeholder: 'sample@gmail.com',
                  required: true,
                  pattern: pattern.email,
                },
                {
                  divClass: 'col-12 col-lg-6',
                  name: 'mobile',
                  key: 'mobile',
                  placeholder: 'Enter 10 digit Mobile number',
                  required: true,
                  pattern: pattern.mobile,
                },
                {
                  type: 'textarea',
                  areaRow: '3',
                  divClass: 'col-12',
                  name: 'address',
                  key: 'address',
                  placeholder: '',
                  required: true,
                },
                {
                  type: 'select',
                  options: data,
                  divClass: 'col-12 col-lg-4',
                  name: 'state',
                  key: 'state',
                  required: true,
                  onChange: {
                    from: 'children',
                    to: 'city',
                    options: data
                  }
                },
                {
                  divClass: 'col-12 col-lg-4',
                  type: 'select',
                  name: 'city',
                  key: 'city',
                  required: true,
                  options: data.filter(item => item.value === val?.state)[0]?.children
                },
                {
                  divClass: 'col-12 col-lg-4',
                  name: 'pincode',
                  key: 'pincode',
                  required: true,
                },
              ]}
            />
          </Col>
        </Row>
      </Container>
      <ToastContainer />
    </div>
  )
}

export default SellerAccount
