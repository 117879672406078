import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Slider from 'react-slick';
import { frontEnd_API, siteConfig } from '../../Config/Config';
import { useSelector } from 'react-redux';
import { ProductSliderList, ProductSliderListTwo } from '../../Data/localData';
import Redirection from '../../Components/Redirection/Redirection';
import CommonSlider from '../../Components/CommonSlider';
import SearchBar from '../../Components/SearchBar';

function SellerHomeCategory() {

    const user = useSelector((state) => state.user.value);
    const [category, setCategory] = useState([]);
    useEffect(() => {
        const customHeader = {
            headers: {
                'token': `Bearer ${user?.token}`,
                'Content-Type': 'application/json'
            }
        }
        axios.get(frontEnd_API.getcategory, customHeader)
            .then((res) => {
                setCategory(res?.data?.data);
                console.log(res?.data?.data);
            })
            .catch((e) => {
                console.log("e::", e);
            })
    }, [user?.token])

    const settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: (category.length <= 5) ? category.length : 5,
        initialSlide: 0,
        autoplay: true,
        autoplaySpeed: 2000,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: (category.length <= 4) ? (category.length - 1) : 4,
                }
            },
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 3,
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 3,
                    infinite: true,
                }
            },
            {
                breakpoint: 400,
                settings: {
                    slidesToShow: 3,
                }
            }
        ]
    };

    const newSettings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 0,
        autoplay: false,
        autoplaySpeed: 2000
    };

    document.title = `Category Home | ${siteConfig?.name}`;

    return (
        <div className='custom-seller-category py-3 py-lg-4 px-0 px-lg-2'>

            {/* Search Button */}
            <SearchBar />

            {/* Explore Printfuse Best */}
            {
                (category) &&
                <Container className='slider-container custom-seller-category-slider py-4'>
                    <div className='pb-3'>
                        <h4 className='fw-semibold'>Explore Printifuse's bests</h4>
                        <p className='mb-0 fs-sm-12' style={{ width: "70%" }}>Here are some of the most popular product categories in our catalog.</p>
                    </div>
                    <Slider {...settings}>
                        {
                            category.map((item, index) => {
                                return (
                                    <Redirection
                                        key={index}
                                        redirectValue={{
                                            url: `/category/${item.label}`,
                                            data: item
                                        }}
                                    >
                                        <Link className='d-grid justify-content-center text-center slider-category-container'>
                                            <div className='slider-category-img-container'>
                                                <img src={(index % 2 === 0) ? require('../../Assets/Images/banner-1-3.jpg') : require('../../Assets/Images/banner-1-9.png')} alt="" />
                                            </div>
                                            <h6 className='fs-sm-14'>{item.label}</h6>
                                        </Link>
                                    </Redirection>
                                )
                            })
                        }
                    </Slider>
                </Container>
            }

            {/* Banner */}
            <Container className='custom-seller-category-banner-final p-2 py-4 pt-5'>
                <div className='seller-category-slider-new-item d-grid d-sm-flex'>
                    <div className='seller-category-slider-new-img d-flex d-sm-none'>
                        <img src={require('../../Assets/Images/back1.png')} alt="" />
                    </div>
                    <div className='seller-category-slider-new-content p-2 p-sm-3 p-md-4 p-lg-5 gap-2 gap-sm-1 gap-md-2'>
                        <h2 className='fw-bold fs-lg-20 fs-md-12 mb-1'>Bring Your Unique Designs to Life on Quality Tees</h2>
                        <p className='fs-lg-14 fs-md-10 fs-sm-10 mb-2'>Create personalized t-shirts with ease. Design your own prints and shop unique styles at Print Everywhere You Want.</p>
                        <Link className='custom-design-btn fw-bold fs-md-12 fs-sm-14 px-2 py-1 py-lg-2'>Start Designing</Link>
                    </div>
                    <div className='seller-category-slider-new-img d-none d-sm-flex'>
                        <img src={require('../../Assets/Images/back1.png')} alt="" />
                    </div>
                </div>
            </Container>

            {/* Top Trennding Products */}
            <Container className='py-4 px-0'>
                <CommonSlider
                    data={ProductSliderListTwo}
                    title={'Explore the newest arrivals'}
                    description={'Coming in hot—the latest additions to our product catalog you don’t want to miss'}
                />
            </Container>

            {/* Explore What's new */}
            <Container className='seller-category-slider-new px-3 px-sm-2 p-2 py-4'>
                <div>
                    <h4 className='fw-semibold mb-3'>Explore what's new</h4>
                </div>
                <Slider {...newSettings}>
                    <div className='seller-category-slider-new-item d-grid d-sm-flex'>
                        <div className='seller-category-slider-new-img'>
                            <img src={require('../../Assets/Images/99070a2784093afa2be177e2c0694283.jfif')} alt="" />
                        </div>
                        <div className='seller-category-slider-new-content p-2 p-sm-3 p-md-4 p-lg-5'>
                            <h5 className='fw-semibold fs-sm-18'>New: All-Over Print Flare Leggings</h5>
                            <p className='fs-sm-14 mb-1 mb-sm-2'>These leggings are a must-have for lifestyle enthusiasts. They are trendy, flattering, and comfortable, with a high waist, butt-lifting cut, and flared leg bottoms.</p>
                            <Link>See item</Link>
                        </div>
                    </div>
                    <div className='seller-category-slider-new-item d-grid d-sm-flex'>
                        <div className='seller-category-slider-new-img'>
                            <img src={require('../../Assets/Images/ec69fcb34bbc5fb760e0fa01ddd049f9.jfif')} alt="" />
                        </div>
                        <div className='seller-category-slider-new-content p-2 p-sm-3 p-md-4 p-lg-5'>
                            <h5 className='fw-semibold fs-sm-18'>New: Insulated Tumbler with a Straw</h5>
                            <p className='fs-sm-14 mb-1 mb-sm-2'>Tired of mundane drinkware? Say hello to a burst of color and flair with the Insulated Tumbler’s range of trendy hues.</p>
                            <Link>See item</Link>
                        </div>
                    </div>
                </Slider>
            </Container>

            {/* Top Trennding Products */}
            <Container className='py-4 px-0'>
                <CommonSlider
                    data={ProductSliderList}
                    title={'Suggested for you'}
                />
            </Container>

        </div >
    )
}

export default SellerHomeCategory