import React from 'react'
import { Accordion, Col, Container, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { TbPointFilled } from "react-icons/tb";
import { siteConfig } from '../../Config/Config';

function SellerInsights() {

    document.title = `Insights | ${siteConfig?.name}`;
    
    return (
        <div className='py-5 seller-insights'>
            <Container className='seller-insights-container'>
                <Row className='flex-center-align'>
                    <Col className='col-12 col-lg-8 d-grid gap-4 text-center'>
                        <div className='d-grid gap-3 gap-lg-2'>
                            <h4 className='fw-semibold fs-md-20 m-0'>Unlock your seller insights with 20 monthly orders.</h4>
                            <p className='p-color fs-lg-14 fs-sm-12 m-0'>To provide you with useful data and statistics on your store, you’ll need at least 20 sales per month.</p>
                            <p className='p-color fs-lg-14 fs-sm-12 m-0'>Curious on how insights can help your business? <Link>Learn how here.</Link></p>
                        </div>
                        <div className='d-flex seller-box no-border-rad p-3 gap-2'>
                            <div><i className="color-info bi bi-info-circle-fill"></i></div>
                            <div className='d-grid text-start gap-2'>
                                <span className='fw-semibold fs-sm-14'>Looking to boost your sales?</span>
                                <p className='p-color fs-14 fs-sm-12 m-0'>Check out <Link className='text-dark'>this article</Link> on how to increase conversions and get more sales for your store. You can also watch these videos for valuable sales tips from expert sellers.</p>
                            </div>
                        </div>
                        <div className='d-grid gap-5'>
                            <div className='d-grid d-md-flex gap-3'>
                                <div className='w-100 youtube-video-container'>
                                    <iframe src="https://www.youtube.com/embed/RzVvThhjAKw?si=ojwpLK9Tsd0Jzgsd" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                                </div>
                                <div className='w-100 youtube-video-container'>
                                    <iframe src="https://www.youtube.com/embed/hlWiI4xVXKY?si=TZx4spFd89h4I53D" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                                </div>
                            </div>

                            <div className='d-grid gap-2'>
                                <h4 className='fw-semibold fs-sm-20 m-0'>Growing your sales with Printify Insights</h4>
                                <div className='w-100 youtube-video-container'>
                                    <iframe src="https://www.youtube.com/embed/kjI9vD9PCtM?si=ZX-bh1EWyDmQHmjb" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                                </div>
                                <Accordion className='seller-inghts-accordion mt-4'>
                                    <Accordion.Item eventKey="0">
                                        <Accordion.Header> <b className='fw-semibold d-flex align-items-start align-items-sm-center gap-1'><TbPointFilled />What are Printify Insights?</b> </Accordion.Header>
                                        <Accordion.Body>
                                            <div className='fs-14 fs-sm-12 text-start d-grid gap-4'>
                                                <p className='m-0'>
                                                    <b>Printfuse Insights</b> provides a tailored overview of your business and product performance. Through the use of intuitive dashboards, you can effortlessly track your store’s growth and make informed business decisions based on data. Additionally, you can access details about the best-selling Printfuse products and their variants to see what’s currently popular.
                                                </p>
                                                <b>Key Features:</b>
                                                <ul className='d-grid gap-3'>
                                                    <li><b className='fw-semibold'>Comprehensive Dashboards:</b> Monitor your store’s key performance indicators (KPIs) in one place. Visualize sales trends, customer behavior, and product performance with easy-to-understand charts and graphs.</li>
                                                    <li><b className='fw-semibold'>Growth Tracking:</b> Keep an eye on your store’s growth over time. Analyze metrics such as revenue, order volume, and customer acquisition to understand how your business is evolving.</li>
                                                    <li><b className='fw-semibold'>Best-Selling Products:</b> Discover which Printfuse products and variants are driving the most sales. See what's trending in real-time and make strategic decisions on stock and marketing efforts.</li>
                                                    <li><b className='fw-semibold'>Product Performance Analysis:</b> Dive deep into individual product performance. Identify top performers and underperformers to optimize your product lineup and improve profitability.</li>
                                                    <li><b className='fw-semibold'>Customer Insights:</b> Gain a better understanding of your customer base. Track demographics, purchasing habits, and customer feedback to tailor your offerings and marketing strategies.</li>
                                                    <li><b className='fw-semibold'>Custom Reports:</b> Generate custom reports tailored to your specific needs. Export data for further analysis or share insights with your team to align your business strategies.</li>
                                                    <li><b className='fw-semibold'>Inventory Management:</b> Stay on top of your inventory levels. Predict future demand and ensure you always have the right products in stock to meet customer needs.</li>
                                                </ul>
                                            </div>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="1">
                                        <Accordion.Header> <b className='fw-semibold d-flex align-items-start align-items-sm-center gap-1'><TbPointFilled />How can I calculate my store’s profit?</b> </Accordion.Header>
                                        <Accordion.Body>
                                            <div className='fs-14 fs-sm-12 d-grid text-start gap-3'>
                                                <p>
                                                    <span className='fw-semibold'>Printfuse Insights: Overview Dashboard</span>
                                                    The Overview dashboard in Printfuse Insights provides a comprehensive look at your business expenses, broken down into production and shipping costs. Total costs encompass production, shipping, and taxes.
                                                </p>
                                                <b>Key Features:</b>
                                                <ul className='d-grid gap-2'>
                                                    <li><b className='fw-semibold'>Expenses Breakdown:</b> View detailed expenses for production and shipping.</li>
                                                    <li><b className='fw-semibold'>Total Costs:</b> See the combined costs, including production, shipping, and taxes.</li>
                                                </ul>
                                                <b>Calculating Store Profit:</b>
                                                <p>Although store profit is not currently displayed in this dashboard, it is a feature we plan to include in future releases. In the meantime, you can calculate your store’s profit by following these steps:</p>
                                                <b>1. Filter Your Data:</b>
                                                <ul className='d-grid gap-2'>
                                                    <li><b>Time Range:</b> Choose a specific time frame.</li>
                                                    <li><b>Store:</b> Select one or more of your stores.</li>
                                                </ul>
                                                <b>2. View Expenses:</b>
                                                <ul>
                                                    <li>The money spent on the production and shipping of your orders will be displayed under <b>Expenses.</b></li>
                                                </ul>
                                                <b>3. Calculate Profit:</b>
                                                <ul className='d-grid gap-2'>
                                                    <li>Use the following formula:</li>
                                                    <li>
                                                        <b className='text-success fs-16'>Profit </b>
                                                        = <span className='color-info'>Store sales</span> (found in your sales channel) - <span className='text-danger'>Expenses</span> (found in Insights) - <span className='text-danger'>Any other expenses</span> (such as sales channel subscription)
                                                    </li>
                                                </ul>
                                            </div>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="2">
                                        <Accordion.Header> <b className='fw-semibold d-flex align-items-start align-items-sm-center gap-1'><TbPointFilled />How can I track the quality of my products?</b> </Accordion.Header>
                                        <Accordion.Body>
                                            <div className='fs-14 fs-sm-12 d-grid text-start gap-4'>
                                                <p>
                                                    <span className='fw-semibold'>Printfuse Insights: Quality Dashboard</span>
                                                    Printfuse Insights offers a Quality dashboard that helps you monitor the overall quality of your products. This dashboard compiles information about quality issues reported by your customers, allowing you to assess your reprint rate and compare the quality of different Print Providers.
                                                </p>
                                                <b>Key Features:</b>
                                                <ul className='d-grid gap-3'>
                                                    <li><b className='fw-semibold'>Quality Issues:</b> View reports of quality issues submitted by your customers. Track common problems and identify areas for improvement.</li>
                                                    <li><b className='fw-semibold'>Reprint Rate:</b> Monitor your reprint rate to understand the frequency of product reprints due to quality issues. A lower reprint rate indicates better product quality and higher customer satisfaction.</li>
                                                    <li><b className='fw-semibold'>Print Provider Comparison:</b> Compare the quality of different Print Providers. Use this information to choose the best providers and improve your product offerings.</li>
                                                </ul>
                                            </div>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default SellerInsights
