import React, { useEffect, useState } from 'react'
import { Button, Col, Container, Dropdown, Modal, Row, Tab, Tabs } from 'react-bootstrap'
import { GiNotebook } from 'react-icons/gi'
import { customFilter } from '../../Data/localData';
import { DateRangePicker } from 'react-date-range';
import { MdOutlineProductionQuantityLimits } from "react-icons/md";
import { useNavigate } from 'react-router-dom';
import { frontEnd_API, header, siteConfig } from '../../Config/Config';
import axios from 'axios';

function SellerOrders() {

  const [showFilter, setShowFilter] = useState(false);
  const [calendar, setCalendar] = useState(false);
  const [filters, setFilters] = useState([]);
  const navigate = useNavigate();
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [orders, setOrders] = useState();
  const [state, setState] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: 'selection'
    }
  ]);

  const handleChange = (event) => {
    const value = event.target.value;
    setFilters((prev) =>
      prev.includes(value)
        ? prev.filter((item) => item !== value)
        : [...prev, value]
    );
  }

  const handleDateChange = (e) => {
    console.log(e);
    setState([e]);
  }

  useEffect(() => {
    console.log(filters);
  }, [filters])

  useEffect(() => {
    setStartDate((state[0].startDate) && `${state[0]?.startDate.getDate()}/${state[0]?.startDate.getMonth() + 1}/${state[0]?.startDate.getFullYear()}`)

    setEndDate((state[0].endDate) && `${state[0]?.endDate.getDate()}/${state[0]?.endDate.getMonth() + 1}/${state[0]?.endDate.getFullYear()}`)
  }, [state, startDate, endDate])

  const handleClick = (val) => {
    setFilters((prevData) => prevData.filter(value => value !== val))
    setState([
      {
        startDate: '',
        endDate: '',
        key: 'selection'
      }
    ]);
  }

  const getOrderList = async () => {
    try {
      const { data } = await axios.put(frontEnd_API.orders, {}, header)
      console.log("data::", data);
      setOrders(data?.data);
    }
    catch (e) {
      console.log("e::", e);
    }
  }

  useEffect(() => {
    getOrderList();
  }, [])

  document.title = `Seller Orders | ${siteConfig?.name}`;

  return (
    <div className='seller-order p-0' style={{minHeight: "100vh"}}>
      <Container className='seller-order-main-container pb-5'>
        <div className=' d-grid d-sm-flex justify-content-sm-between gap-3 py-4'>
          <h2 className='fw-bold  m-0'>Orders</h2>
          <div className='flex-between-align gap-2'>
            <Dropdown className='d-none d-sm-block'>
              <Dropdown.Toggle id="dropdown-basic" className='custom-dropdown-one flex-center-align py-2 fw-semibold fs-14'>
                <span className='d-none d-sm-block'>CSV Import / Export</span>
                <i className='d-block d-sm-none bi bi-caret-down-fill'></i>
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item>Export as CSV</Dropdown.Item>
                <Dropdown.Item>Import CSV file</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>

            <Dropdown className='w-100'>
              <Dropdown.Toggle id="dropdown-basic" className='custom-dropdown-two w-100 py-2 fw-semibold fs-14'>
                Create order
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item>Manual</Dropdown.Item>
                <Dropdown.Item>Bulk</Dropdown.Item>
                <Dropdown.Item>Sample</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>

            <Dropdown className='d-block d-sm-none'>
              <Dropdown.Toggle id="dropdown-basic" className='custom-dropdown-one flex-center-align py-2 fw-semibold fs-14'>
                <span className='d-none d-sm-block'>CSV Import / Export</span>
                <i className='d-block d-sm-none bi bi-caret-down-fill'></i>
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item>Export as CSV</Dropdown.Item>
                <Dropdown.Item>Import CSV file</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
        <div className='seller-order-container p-2 p-sm-3'>
          <Tabs
            defaultActiveKey="printfuseOrders"
            id="fill-tab-example"
            className="mb-3 custom-tabs"
            fill
          >
            <Tab eventKey="printfuseOrders" title="Printfuse orders">
              <div className='py-2 d-grid gap-3 custom-btn-scrollbar'>
                {/* Search Button */}
                <div className='d-flex align-items-center gap-2 d-md-grid'>
                  <Container className='custom-seller-category-search px-0'>
                    <div className='d-flex align-items-center gap-2 gap-lg-3 px-2 px-lg-3'>
                      <label className='bi bi-search' htmlFor='text'></label>
                      <input type="text" className='py-2' id='text' placeholder='Search by order number, customer, or product name' autoComplete='off' />
                    </div>
                  </Container>
                  <div className='d-block d-md-none'
                    style={{ cursor: 'pointer' }}
                    onClick={() => setShowFilter(!showFilter)}
                  ><i className="bi bi-funnel-fill px-3 py-2 seller-box"></i></div>


                  {/* Filters */}
                  <Modal
                    show={showFilter}
                    onHide={() => setShowFilter(!showFilter)}
                    backdrop="static"
                    keyboard={false}
                  >
                    <Modal.Header closeButton>
                      <Modal.Title>Filter Invoices</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <div className='d-grid gap-3'>
                        {
                          customFilter.map((item, index) => {
                            return (
                              <div key={index} className='d-grid w-100 custom-select-option'>
                                <Dropdown className='d-inline w-100' autoClose="outside">
                                  <Dropdown.Toggle variant="light" id="dropdown-autoclose-outside" className='seller-box no-border-rad text-start w-100 fw-semibold text-capitalize'>
                                    {item.title}
                                  </Dropdown.Toggle>
                                  <Dropdown.Menu className='w-100 gap-1'>
                                    {
                                      item.data.map((item, index) => {
                                        return (
                                          <Dropdown.Item key={index} as={'div'} className='flex-between-align gap-1 gap-md-2 justify-content-start w-100'>
                                            <input type="checkbox"
                                              name={item.label} id={item.label}
                                              value={item.value}
                                              checked={filters?.includes(item.value)}
                                              onChange={handleChange} />

                                            <label htmlFor={item.label} className='text-capitalize fs-18 fs-lg-14 fs-sm-12'>{item.label}</label>
                                          </Dropdown.Item>
                                        )
                                      })
                                    }
                                  </Dropdown.Menu>
                                </Dropdown>
                              </div>
                            )
                          })
                        }
                        <div className='d-grid w-100'>
                          <div className='custom-select-container sellet-box d-grid'>
                            <span className='w-100 d-flex d-lg-flex py-2 px-3 px-md-3 custom-select fs-16' onClick={() => setCalendar(!calendar)}>
                              <b className='text-dark pe-1'>Period:</b>{(startDate && endDate) ? `${startDate} - ${endDate}` : 'All Date'}
                            </span>
                            <div className={`custom-select-calendar ${(!calendar) && 'd-none'}`}>
                              <DateRangePicker
                                ranges={state}
                                onChange={(e) => handleDateChange(e.selection)}
                              >
                              </DateRangePicker>
                              <button className='custom-close-btn fs-10 fw-semibold' onClick={() => setCalendar(false)}>Close</button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Modal.Body>
                    <Modal.Footer>
                      <Button variant="secondary" onClick={() => setShowFilter(false)}>
                        Close
                      </Button>
                      <Button variant="primary" onClick={() => setShowFilter(false)}>Apply Filters</Button>
                    </Modal.Footer>
                  </Modal>
                </div>
                <div className='d-none d-md-flex'>
                  {
                    customFilter.map((item, index) => {
                      return (
                        <div key={index} className='d-grid w-100 custom-select-option'>
                          <Dropdown className='d-inline w-100' autoClose="outside">
                            <Dropdown.Toggle variant="light" id="dropdown-autoclose-outside" className='seller-box no-border-rad text-start w-100 fw-semibold text-capitalize'>
                              {item.title}
                            </Dropdown.Toggle>
                            <Dropdown.Menu className='w-100 gap-1'>
                              {
                                item.data.map((item, index) => {
                                  return (
                                    <Dropdown.Item key={index} as={'div'} className='flex-between-align gap-1 gap-md-2 justify-content-start w-100'>
                                      <input type="checkbox"
                                        name={item.label} id={item.label}
                                        value={item.value}
                                        checked={filters?.includes(item.value)}
                                        onChange={handleChange} />

                                      <label htmlFor={item.label} className='text-capitalize fs-18 fs-lg-14 fs-sm-12'>{item.label}</label>
                                    </Dropdown.Item>
                                  )
                                })
                              }
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>
                      )
                    })
                  }
                  <div className='d-grid w-100'>
                    <div className='custom-select-container sellet-box d-grid'>
                      <span className='w-100 d-grid d-lg-flex fs-12 py-2 px-1 px-md-3 custom-select fs-md-10' onClick={() => setCalendar(!calendar)}>
                        <b className='text-dark pe-lg-1 d-block d-md-none d-lg-block'>Period:</b>{(startDate && endDate) ? `${startDate} - ${endDate}` : 'All Date'}
                      </span>
                      <div className={`custom-select-calendar ${(!calendar) && 'd-none'}`}>
                        <DateRangePicker
                          ranges={state}
                          onChange={(e) => handleDateChange(e.selection)}
                        >
                        </DateRangePicker>
                        <button className='custom-close-btn fs-10 fw-semibold' onClick={() => setCalendar(false)}>Close</button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='d-flex custom-filter-btns gap-2'>
                  <button className={`fs-12 px-2 gap-1`} onClick={() => handleClick(state[0])}>
                    {(startDate && endDate) ? `${startDate} - ${endDate}` : 'All date'}
                    {
                      (startDate && endDate)
                      && <i className="bi bi-x-circle-fill color-info"></i>
                    }
                  </button>
                  {
                    filters.map((item, index) => {
                      return (
                        <button key={index} className='fs-12 px-2 gap-1' onClick={() => handleClick(item)}>{item}
                          <i className="bi bi-x-circle-fill color-info"></i>
                        </button>
                      )
                    })
                  }
                </div>

                <Container className='custom-order-list'>
                  <Row className='custom-order-list-filter-bar py-3 flex-between-align mb-2 mb-md-0'>
                    <Col className='col-12 col-md-1 d-grid'>
                      <div className='custom-check-box-container fs-14 fw-semibold d-flex gap-2 justify-content-start justify-content-md-center align-items-center'>
                        <input type="checkbox" id='check' />
                        <label className='d-md-none' htmlFor='check'>Select all orders</label>
                      </div>
                    </Col>
                    <Col className='d-none d-md-flex col-md-3 flex-between-align'>
                      <div className='fs-14 fw-semibold w-100'>Order</div>
                      <div className='fs-14 fw-semibold w-100'>production</div>
                    </Col>
                    <Col className='d-none d-md-flex col-md-4 flex-between-align'>
                      <div className='fs-14 fw-semibold w-100'>Customer</div>
                      <div className='fs-14 fw-semibold w-100'>Net cost</div>
                    </Col>
                    <Col className='d-none d-md-flex col-md-2'>
                      <div className='fs-14 fw-semibold '>Tracking</div>
                    </Col>
                    <Col className='d-none d-md-block col-md-2'>
                      <div className='fs-14 fw-semibold'>Status</div>
                    </Col>
                  </Row>
                  {/*  */}
                  {
                    (!orders?.length > 0)
                      ? <div className='py-5'>
                        <div className='custom-transaction-tab d-grid justify-content-center text-center gap-2'>
                          <div>
                            <GiNotebook />
                          </div>
                          <h5 className='fw-semibold'>No invoices yet</h5>
                          <p className='p-color fs-12'>Invoices will appear here after you fulfill orders.</p>
                        </div>
                      </div>
                      :
                      <>
                        {
                          orders.map((item, index) => {
                            return (
                              <Row
                                key={index}
                                className='custom-order-list-bar-item py-3 flex-between-align gy-2 gy-md-0 mb-2 mb-md-0'
                                onClick={() => navigate(`/orders/${item?.orderId}`, {
                                  state: {
                                    data: item
                                  }
                                })}
                              >
                                <Col className='col-12 col-md-1 m-0'>
                                  <div className='custom-check-box-container fs-14 fw-semibold d-flex gap-2 justify-content-start justify-content-md-center align-items-center'>
                                    <input type="checkbox" id={item.orderId} onClick={(e) => e.stopPropagation()} />
                                    <label className='d-md-none' htmlFor={item.orderId}>Select order</label>
                                  </div>
                                </Col>
                                <Col className='col-12 col-md-3 flex-between-align'>
                                  <div className='d-grid w-100'>
                                    <b className='fs-14 text-capitalize'>Manual</b>
                                    <span className='p-color fs-14'>#{item.orderId}</span>
                                  </div>
                                  <div className='text-capitalize w-100 p-color fw-semibold fs-14 px-3 px-md-0'>Not Available</div>
                                </Col>
                                <Col className='col-12 col-md-4 flex-between-align'>
                                  <div className='fs-14 w-100'>{item?.orderBillingName}</div>
                                  <div className='fs-14 w-100 px-3 px-md-0'>₹{item?.orderNetAmount}</div>
                                </Col>
                                <Col className='col-12 col-md-2 d-grid'>
                                  <div className='d-flex align-items-center'>
                                    <span className='d-block d-md-none fs-12 p-color w-100'>Tracking :</span>
                                    <div className='w-100 d-flex px-3 px-md-0'>
                                      <div className='seller-box px-3 fs-14 py-1 p-color'>Not Available</div>
                                    </div>
                                  </div>
                                </Col>
                                <Col className='col-12 col-md-2 custom-status-bar-container'>
                                  <div className='d-grid gap-2'>
                                    <div className='custom-status-bar-content'>
                                      <div className='custom-status-bar d-flex align-items-center gap-2'>
                                        <span></span>
                                        <b className='text-capitalize'>on hold</b>
                                      </div>
                                      <div className='fs-12 text-danger'>Payment not received</div>
                                    </div>
                                    <div className='custom-status-bar-btn d-grid'>
                                      <button className='fs-12 fw-semibold text-center'>Update Payment</button>
                                    </div>
                                  </div>
                                </Col>
                              </Row>
                            )
                          })
                        }
                      </>
                  }
                </Container>
              </div>
            </Tab>
            <Tab eventKey="otherOrders" title="Other orders">
              <Container className='custom-seller-category-search px-0 py-2'>
                <div className='d-flex align-items-center gap-2 gap-lg-3 px-2 px-lg-3'>
                  <label className='bi bi-search' htmlFor='text'></label>
                  <input type="text" className='py-2' id='text' placeholder='Search by order number, customer, or product name' autoComplete='off' />
                </div>

              </Container>
              <Container>
                <Row>
                  <Col className='d-none d-md-block col-md-2'>
                    <span className='fs-12 fw-semibold p-color'>Order</span>
                  </Col>
                  <Col className='d-none d-md-block col-md-2'>
                    <span className='fs-12 fw-semibold p-color'>Created</span>
                  </Col>
                  <Col className='d-none d-md-block col-md-4'>
                    <span className='fs-12 fw-semibold p-color'>Customer</span>
                  </Col>
                  <Col className='d-none d-md-block col-md-2'>
                    <span className='fs-12 fw-semibold p-color'>Production cost</span>
                  </Col>
                  <Col className='d-none d-md-block col-md-2'>
                    <span className='fs-12 fw-semibold p-color'>Status</span>
                  </Col>
                </Row>
              </Container>
              <div className='py-2 flex-center-align py-5'>
                <div className='custom-transaction-tab d-grid justify-content-center text-center'>
                  <div>
                    <MdOutlineProductionQuantityLimits />
                  </div>
                  <h5 className='fw-semibold'>No other orders yet</h5>
                </div>
              </div>
            </Tab>

          </Tabs>

        </div>
      </Container >
    </div >
  )
}

export default SellerOrders
