import React, { useEffect, useState } from 'react'
import { Accordion, Button, Col, Container, Modal, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { subscriptionProduct } from '../../Data/localData';
import { frontEnd_API, header, siteConfig } from '../../Config/Config';
import { GoDotFill } from 'react-icons/go'
import axios from 'axios';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { updateUser } from '../../Store/Slices/UserSlice';

function SellerSubscription() {

    const [activeBtn, setActiveBtn] = useState(false);
    const [subList, setSubList] = useState();
    const [show, setShow] = useState(false);
    const [selectedData, setSelectedData] = useState();
    const [subValue, setSubValue] = useState();
    const dispatch = useDispatch();

    const getSubscriptionList = async () => {
        try {
            const { data } = await axios.get(frontEnd_API.pricing, header)
            setSubList(data?.data);
            console.log("data::", data);
        }
        catch (e) {
            console.log("err::", e);
        }
    }

    useEffect(() => {
        getSubscriptionList();
    }, [])

    const handleSubscription = async () => {
        try {
            console.log("subValue::", subValue);
            console.log("header::", header);
            const { data } = await axios.post(frontEnd_API.sellersubscription, subValue, header)
            console.log("data::", data);
            dispatch(updateUser(data?.data));
            
            setShow(false);
        }
        catch (e) {
            console.log("err::", e);
        }
    }

    document.title = `Subscription | ${siteConfig?.name}`;

    const listData = [
        [
            "Use on 1 website",
            "Personal License",
            "20 Basic Elements",
            "Basic Dynamic Data",
            "Basic Design Library",
            "Basic support"
        ],
        [
            "Use on 1 website",
            "Personal License",
            "100 pro Elements",
            "Full Dynamic Data",
            "Full Design Library",
            "Priority support"
        ],
        [
            "Use on unlimited websites",
            "Commercial License",
            "100 Basic Elements",
            "Full Dynamic Data",
            "Full Design Library",
            "Priority support"
        ]
    ]
    return (
        <div>
            <Container fluid className='p-0 d-grid seller-subscription-main-container'>
                <div className='seller-subscription-sub-container-first py-5'>
                    <Container>
                        <Row className='d-flex align-items-center align-content-center'>
                            <Col className='col-12 col-md-7 text-center text-md-start d-grid align-content-center gap-3 gap-sm-2 gap-md-3'>
                                <h1 className='fw-bold m-0' style={{ letterSpacing: "2px" }}>Subscribe to <br /> Printfuse Premium</h1>
                                <h4 className='fw-semibold m-0 fs-sm-18'>Get up to 20% discount on all products</h4>
                            </Col>
                            <Col className='col-12 col-md-5 mt-5 mt-md-0'>
                                <div className='custom-seller-subscription-banner d-grid'>
                                    {/* Package Content */}
                                    <div className='d-grid p-3 p-sm-4'>
                                        <div className='d-grid d-sm-flex d-md-grid d-lg-flex custom-seller-subscription-banner-btn'>
                                            <button className='btn-first fs-14 fw-semibold' onClick={() => setActiveBtn(!activeBtn)} style={{ backgroundColor: `${(!activeBtn) ? 'rgb(0, 0, 48)' : 'white'}`, color: `${(!activeBtn) ? 'white' : 'rgb(0, 0, 48)'}` }}>{(!activeBtn) && <i className="bi bi-check-circle pe-1" />}Monthly</button>
                                            <button className='btn-second fs-14 fw-semibold' onClick={() => setActiveBtn(!activeBtn)} style={{ backgroundColor: `${(activeBtn) ? 'rgb(0, 0, 48)' : 'white'}`, color: `${(activeBtn) ? 'white' : 'rgb(0, 0, 48)'}` }}>{(activeBtn) && <i className="bi bi-check-circle pe-1" />}Yearly (save 14%)</button>
                                        </div>
                                        <h4 className='fw-semibold m-0 py-3 py-sm-4'>₹ {(!activeBtn) ? '2499.99' : '1999'} / per month {(activeBtn) && <span className='p-color fs-12'>(Billed annually)</span>}</h4>
                                        <div className='d-grid fs-14 fs-sm-12 gap-2'>
                                            <div className="d-flex align-items-start gap-1">
                                                <i className="bi bi-check-circle"></i>
                                                <span><b className='fw-semibold'>10</b> stores per account</span>
                                            </div>
                                            <div className="d-flex align-items-start gap-1">
                                                <i className="bi bi-check-circle"></i>
                                                <span><b className='fw-semibold'>Unlimited </b>product designs</span>
                                            </div>
                                            <div className="d-flex align-items-start gap-1">
                                                <i className="bi bi-check-circle"></i>
                                                <span>Up to<b className='fw-semibold'> 20%</b> discount on all products</span>
                                            </div>
                                            <div className="d-flex align-items-start gap-1">
                                                <i className="bi bi-check-circle"></i>
                                                <span>Order management with <b className='fw-semibold'>Printfuse Connect
                                                    <Link className=" ms-1 bi bi-info-circle-fill" />
                                                </b></span>
                                            </div>
                                        </div>
                                        <button className='get-started-btn mt-3 fw-semibold'>Get Started</button>
                                    </div>

                                    {/* Dropdown */}
                                    <Accordion>
                                        <Accordion.Item eventKey="0">
                                            <Accordion.Header> <b className='d-flex gap-1 fw-semibold fs-sm-14'><i className="bi bi-tag"></i>Have a Subscription Coupon?</b> </Accordion.Header>
                                            <Accordion.Body className='d-flex gap-2'>
                                                <input type="text" className='w-100 px-2' placeholder='Enter coupon code' />
                                                <Link className='py-2 px-3 apply-btn'>Apply</Link>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    </Accordion>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>

                {/* From Home List */}
                <div className='custom-price-list'>
                    <Container fluid className='p-0 p-lg-3'>
                        <Container fluid className='custom-img-container p-0'>
                            <Container className='custom-img-section'>
                                <div className='custom-img-section-contain-color'></div>
                                <div className='custom-img-section-contain'></div>
                                <div className='custom-img-section-contain-blend-center'></div>
                                <div className='custom-img-section-contain-blend-left'></div>
                                <div className='custom-img-section-contain-blend-right'></div>
                                <div className='custom-slider-content pt-5 gap-4 gap-sm-4 d-grid'>
                                    {/* Content Part */}
                                    <div className='custom-slider-content-list flex-center-align align-items-start'>
                                        <div className='custom-slider-content-list-first lg-max-w-750px max-w-600px gap-4 d-grid'>
                                            <div className='text-center custom-slider-heading d-grid gap-4 px-4 px-sm-0'>
                                                <h1 className='h1 m-0 d-flex justify-content-center gap-2 gap-sm-3 flex-wrap pb-sm-2'>
                                                    Our
                                                    <span>Subscription</span>
                                                    List
                                                </h1>
                                            </div>
                                        </div>
                                    </div>
                                    {/* Image Part */}
                                    <Container className='custom-slider-content-list-second cs-container-xl p-0 pb-5 text-center'>
                                        <Row className='justify-content-center gy-4 gy-xl-0'>
                                            {
                                                (subList?.length > 0) &&
                                                subList.map((item, index) => (
                                                    <Col className='col-12 col-sm-6 col-xl-4'>
                                                        <div className='price-list-item p-3'>
                                                            <div className='first-content d-grid p-3 p-sm-4 gap-3'>
                                                                <b className='fw-semibold fs-20 text-capitalize'>{item?.name}</b>
                                                                <h2 className='m-0 display-4 fw-semibold'>
                                                                    {(parseInt(item?.priceForMonthly) !== 0)
                                                                        ? `₹${item?.priceForMonthly}`
                                                                        : "Free"}
                                                                    <span className='h2 fw-semibold'>/ mo</span>
                                                                </h2>
                                                                <h5 className='m-0 fw-semibold'>
                                                                    {(parseInt(item?.priceForYearly) !== 0)
                                                                        ? `₹${item?.priceForYearly}`
                                                                        : "Free"}
                                                                    <span className='fw-semibold'>/ yr</span>
                                                                </h5>
                                                                <p className='m-0 px-sm-4'>
                                                                    Build <b className='fw-semibold'>{(parseInt(item?.storeLimit) !== 0) ? item?.storeLimit : "Unlimited"}</b>  {(parseInt(item?.priceForMonthly) !== 0) ? "websites with every pro feature." : 'website with regular feature.'}
                                                                </p>
                                                                <Link className='fw-semibold fs-18 fs-sm-16'
                                                                    // style={{ backgroundColor: '#12715B', color: 'white' }}
                                                                    onClick={() => {
                                                                        setShow((parseInt(item?.priceForMonthly) !== 0) ? true : false)
                                                                        setSelectedData(item);
                                                                    }}
                                                                >
                                                                    {(parseInt(item?.priceForMonthly) !== 0) ? "Buy Now" : "Free"}
                                                                </Link>
                                                                {/* <span className='fs-sm-14'>Billed annually.</span> */}
                                                            </div>
                                                            <div className='second-content d-grid p-3 pt-sm-4 gap-3'>
                                                                {
                                                                    item?.description.map((item, index) => {
                                                                        return (
                                                                            <div key={index} className='d-flex align-items-center gap-2'>
                                                                                <span>
                                                                                    <i className="bi bi-check fs-25 fs-sm-14"></i>
                                                                                </span>
                                                                                <p className='m-0'>{item}</p>
                                                                            </div>
                                                                        )
                                                                    })
                                                                }
                                                            </div>
                                                            {
                                                                (parseInt(item?.priceForMonthly) !== 0) &&
                                                                <div className='custom-lable fs-14 fw-medium'>Save ₹{item?.priceForMonthly * 12 - item?.priceForYearly}</div>
                                                            }
                                                        </div>
                                                    </Col>
                                                ))
                                            }
                                            <span className='text-center my-5'>Prices exclude any applicable taxes.</span>
                                        </Row>
                                    </Container>
                                </div>
                            </Container>
                        </Container>
                    </Container>
                </div>

                <Modal show={show} onHide={() => setShow(false)} aria-labelledby="contained-modal-title-vcenter" centered>
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">Select Subscription Duration:</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <h3 className='text-capitalize mb-3'>Plan Name : <span className='fw-semibold'>{selectedData?.name}</span></h3>
                        <div className="custom-radio-group">
                            <label className="custom-radio-container align-items-end">
                                <input
                                    type="radio"
                                    name="custom-radio"
                                    defaultValue="option1"
                                    onChange={() => setSubValue({
                                        subscriptionId: selectedData?.id,
                                        type: "monthly",
                                        amount: parseInt(selectedData?.priceForMonthly)
                                    })}
                                />
                                <span className="custom-radio-checkmark" />
                                ₹{selectedData?.priceForMonthly} / mo <span className="fs-14 ps-2">(Renew Monthly)</span>
                            </label>
                            <label className="custom-radio-container">
                                <input
                                    type="radio"
                                    name="custom-radio"
                                    defaultValue="option2"
                                    onChange={() => setSubValue({
                                        subscriptionId: selectedData?.id,
                                        type: "yearly",
                                        amount: parseInt(selectedData?.priceForYearly)
                                    })}
                                />
                                <span className="custom-radio-checkmark" />
                                <span>
                                    ₹{selectedData?.priceForYearly} / yr <span className="fs-14 ps-2">(Renew Yearly)</span>
                                </span>
                                <span className="fs-14 ps-2 text-success">(Save ₹{selectedData?.priceForMonthly * 12 - selectedData?.priceForYearly})</span>
                            </label>
                        </div>


                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => setShow(false)}>
                            Close
                        </Button>
                        <Button variant="primary" onClick={() => {
                            setShow(false)
                            handleSubscription();
                        }}
                            style={{ cursor: `${(subValue) ? 'pointer' : 'no-drop'}` }}
                            disabled={(subValue) ? false : true}
                        >
                            Save Changes
                        </Button>
                    </Modal.Footer>
                </Modal>
                {/* From Home List Over/End*/}

                <div className='seller-subscription-sub-container-second pb-5'>
                    <Container>
                        <h5 className='fw-semibold mb-3'>Discount examples</h5>
                        <Row className='gy-5 gy-lg-0'>
                            {
                                subscriptionProduct.map((item, index) => {
                                    return (
                                        <Col key={index} className='col-12 col-sm-6 col-md-4 col-lg-3 d-grid'>
                                            <div className='seller-sub-img'>
                                                <img src={item.thumbnail} alt="" />
                                            </div>
                                            <div className='seller-sub-cnt d-grid pt-3'>
                                                <h6 className='fw-semibold fs-14'>{item.title}</h6>
                                                <div className='flex-between-align fs-14'>
                                                    <span>Regular price:</span>
                                                    <span>₹{item.regularPrice}</span>
                                                </div>
                                                <div className='flex-between-align fs-14'>
                                                    <span>Printfuse Premium price::</span>
                                                    <span>₹{item.pPrice}</span>
                                                </div>
                                                <hr className='my-2' />
                                                <div className='flex-between-align fs-14'>
                                                    <span>Savings:</span>
                                                    <span>₹{item.regularPrice - item.pPrice}</span>
                                                </div>
                                            </div>
                                        </Col>
                                    )
                                })
                            }
                        </Row>
                        <hr className='my-5' />
                        <div className='d-grid'>
                            <h5 className='fw-semibold text-center'>Check out our product catalog with discounted prices</h5>
                            <div className='flex-center-align pt-3'>
                                <button className='fw-semibold custom-see-discount-btn'>See discounts</button>
                            </div>
                        </div>
                    </Container>
                </div>

                <div className='seller-subscription-sub-container-third py-5'>
                    <Container>
                        <Row>
                            <Col className='col-12 col-md-6 d-grid'>
                                <div className='flex-center-align'>
                                    <div className='custom-info-img-container'>
                                        <img src={require('../../Assets/Images/5.jpg')} alt="" />
                                    </div>
                                </div>
                            </Col>
                            <Col className='col-12 col-md-6 d-grid pt-3 pt-md-0'>
                                <div className='d-grid gap-1'>
                                    <h5 className='fw-semibold m-0'>Hanna Barlow</h5>
                                    <span className='fs-14 fw-semibold'>Co-founder of MK Studio</span>
                                    <p className='p-color fs-14 fs-sm-12'>
                                        One of the main reasons for not being able to make sales is that the product retail price is too high. Printify Premium plan enables you to have lower retail prices. So you can get more conversions, more sales and more profit. Printify subscription plan is for anyone who is serious about running a successful online store.
                                    </p>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <div className='seller-subscription-sub-container-fourth py-5'>
                    <Container>
                        <Row className='px-2'>
                            <Col className='col-12 col-lg-3 px-0 py-4 d-grid align-content-center'>
                                <div className='flex-center-align'>
                                    <div className='custom-coupon-img'>
                                        <img src={require('../../Assets/Images/coupon.png')} alt="" />
                                    </div>
                                </div>
                                <h5 className='fw-semibold text-center custom-heading-text'>Your savings</h5>
                            </Col>
                            <Col className='col-12 col-lg-9 pt-3'>
                                <Row>
                                    <Col className='col-4 border-bottom py-3'>
                                        <h5 className='fw-semibold fs-16 fs-lg-14 fs-sm-12'>Purchase volume</h5>
                                    </Col>
                                    <Col className='col-4 border-bottom py-3'>
                                        <h5 className='fw-semibold fs-16 fs-lg-14 fs-sm-12'>Monthly savings</h5>
                                    </Col>
                                    <Col className='col-4 border-bottom py-3'>
                                        <h5 className='fw-semibold fs-16 fs-lg-14 fs-sm-12'>Yearly savings</h5>
                                    </Col>

                                    <Col className='col-4 border-bottom py-2 py-sm-3'>
                                        <span className='p-color fs-16 fs-lg-14 fs-sm-12 ps-1'>₹750</span>
                                    </Col>
                                    <Col className='col-4 border-bottom py-2 py-sm-3'>
                                        <span className='p-color fs-16 fs-lg-14 fs-sm-12 ps-1'>₹150</span>
                                    </Col>
                                    <Col className='col-4 border-bottom py-2 py-sm-3'>
                                        <span className='p-color fs-16 fs-lg-14 fs-sm-12 ps-1'>₹1800</span>
                                    </Col>

                                    <Col className='col-4 border-bottom py-2 py-sm-3'>
                                        <span className='p-color fs-16 fs-lg-14 fs-sm-12 ps-1'>₹1500</span>
                                    </Col>
                                    <Col className='col-4 border-bottom py-2 py-sm-3'>
                                        <span className='p-color fs-16 fs-lg-14 fs-sm-12 ps-1'>₹300</span>
                                    </Col>
                                    <Col className='col-4 border-bottom py-2 py-sm-3'>
                                        <span className='p-color fs-16 fs-lg-14 fs-sm-12 ps-1'>₹3600</span>
                                    </Col>

                                    <Col className='col-4 border-bottom py-2 py-sm-3'>
                                        <span className='p-color fs-16 fs-lg-14 fs-sm-12 ps-1'>₹5000</span>
                                    </Col>
                                    <Col className='col-4 border-bottom py-2 py-sm-3'>
                                        <span className='p-color fs-16 fs-lg-14 fs-sm-12 ps-1'>₹1000</span>
                                    </Col>
                                    <Col className='col-4 border-bottom py-2 py-sm-3'>
                                        <span className='p-color fs-16 fs-lg-14 fs-sm-12 ps-1'>₹12000</span>
                                    </Col>

                                    <Col className='col-4 border-bottom py-2 py-sm-3'>
                                        <span className='p-color fs-16 fs-lg-14 fs-sm-12 ps-1'>₹10,000</span>
                                    </Col>
                                    <Col className='col-4 border-bottom py-2 py-sm-3'>
                                        <span className='p-color fs-16 fs-lg-14 fs-sm-12 ps-1'>₹2000</span>
                                    </Col>
                                    <Col className='col-4 border-bottom py-2 py-sm-3'>
                                        <span className='p-color fs-16 fs-lg-14 fs-sm-12 ps-1'>₹24,000</span>
                                    </Col>

                                    <Col className='col-4 border-bottom py-2 py-sm-3'>
                                        <span className='p-color fs-16 fs-lg-14 fs-sm-12 ps-1'>₹20,000</span>
                                    </Col>
                                    <Col className='col-4 border-bottom py-2 py-sm-3'>
                                        <span className='p-color fs-16 fs-lg-14 fs-sm-12 ps-1'>₹4000</span>
                                    </Col>
                                    <Col className='col-4 border-bottom py-2 py-sm-3'>
                                        <span className='p-color fs-16 fs-lg-14 fs-sm-12 ps-1'>₹48,000</span>
                                    </Col>

                                </Row>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </Container>
        </div >
    )
}

export default SellerSubscription