import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import FormComponent from '../../Components/FormComponent';
import { pattern } from '../../Config/Constant';
import { frontEnd_API, siteConfig } from '../../Config/Config';
import { MdAdminPanelSettings } from "react-icons/md";
import { ToastContainer } from 'react-toastify';

function SellerAccountSetting() {
    document.title = `Seller Account Settings | ${siteConfig?.name}`;
    return (
        <div className='p-2 seller-account'>
            <Container>
                <h3 className='py-4 m-0 fw-semibold'>Account Settings</h3>
                <Row className='seller-box py-4 px-0 px-sm-3'>
                    <Col className='col-lg-2 d-none d-lg-flex flex-center-align align-items-start seller-account-img-holder'>
                        <div className='flex-center-align'>
                            <MdAdminPanelSettings />
                        </div>
                    </Col>
                    <Col className='col-12 col-lg-10'>
                        <h5 className='fw-semibold m-0 pb-4'>Change Profile Password</h5>
                        <FormComponent
                            action={frontEnd_API.postUpdatePassword}
                            submitFullWidth={false}
                            fields={[
                                {
                                    type: 'password',
                                    divClass: 'col-12',
                                    name: 'Old Password',
                                    key: 'oldPassword',
                                    required: true,
                                },
                                {
                                    type: 'password',
                                    divClass: 'col-12',
                                    name: 'New Password',
                                    key: 'newPassword',
                                    required: true,
                                    pattern: pattern.password,
                                }
                            ]}
                        />
                    </Col>
                </Row>
            </Container>
            <ToastContainer />
        </div>
    )
}

export default SellerAccountSetting