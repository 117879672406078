import React, {  useState } from 'react'
import { Container } from 'react-bootstrap'
import { Link } from 'react-router-dom';

function SearchBar(props = {}) {

    const [inputValue, setInputValue] = useState((props?.defaultValue) ?? '');

    return (
        <Container className='custom-seller-category-search'>
            <div className='d-flex align-items-center gap-2 gap-lg-3 px-2 px-lg-3'>
                <label className='bi bi-search' htmlFor='text'></label>
                <input
                    id='text'
                    type="text"
                    value={inputValue}
                    className='py-2 fs-sm-12'
                    placeholder='Search for products, brands, categories, and print providers'
                    autoComplete='off'
                    onChange={(e) => setInputValue(e.target.value)}
                />
                <span
                    className={`custom-close-btn bi bi-x-lg ${(!inputValue) && 'd-none'}`}
                    onClick={() => setInputValue('')}
                />
                <Link
                    className='custom-search-btn fw-semibold fs-sm-12 px-2 px-sm-3 px-lg-4'
                    to={`/search-result${(inputValue) && '/' + inputValue}`}
                >Search</Link>
            </div>
        </Container>
    )
}

export default SearchBar